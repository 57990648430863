<template>
  <v-row justify="center">
    <v-dialog
      v-if="user.type === 'superadmin' || user.type === 'admin'"
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="add-proyect"
          color="white"
          dark
          absolute
          bottom
          right
          fab
          v-bind="attrs"
          v-on="on"
          elevation="17"
        >
          <v-icon color="black">mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">New Project/Service</span>
          <v-btn
            icon
            color="#003790"
            @click="(dialog = false), reset()"
            class="closeBTN"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container padding-top="15px">
            <v-row>
              <v-expand-transition>
                <v-col cols="12" v-if="message" id="element">
                  <v-alert color="red" dense elevation="11" type="error">
                    <ul>
                      <li v-for="(error, i) in errors" :key="i">{{ error }}</li>
                    </ul>
                  </v-alert>
                </v-col>
              </v-expand-transition>

              <v-text-field
                v-model="name"
                label="Project/Service Name"
                prepend-icon="mdi-clipboard-text"
                color="#003790"
                name="name"
              ></v-text-field>

              <v-tabs color="#003790">
                <v-tab>Service</v-tab>
                <v-tab>Project</v-tab>
                <v-tab-item name="Service">
                  <v-container fluid>
                    <v-row>
                      <v-col cols="5">
                        <v-text-field
                          v-model="po"
                          label="PO# (required)"
                          color="#003790"
                          name="po"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2"></v-col>
                      <v-col cols="5">
                        <v-text-field
                          v-model="so"
                          label="SO#"
                          color="#003790"
                          name="so"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="col-account">
                        <div>
                          <v-icon class="v-icon icon icono"
                            >mdi-google-maps</v-icon
                          >
                        </div>
                        <div class="label-multiselect">
                          <VueGooglePlaces
                            :api-key="apikey"
                            @placechanged="onPlaceChanged"
                            :options="{ fields: ['geometry'] }"
                            country="usa"
                          >
                            <v-text-field
                              label="Enter an an address, zipcode, or location"
                              v-model="address"
                              hide-details="auto"
                            ></v-text-field>
                          </VueGooglePlaces>
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="manager"
                          :items="managers"
                          :item-value="'id'"
                          item-text="name"
                          menu-props="auto"
                          label="Assign PM/SC/PE"
                          hide-details
                          color="#003790"
                          prepend-icon="mdi-account"
                          single-line
                        ></v-select>
                        <v-btn
                          color="#003790"
                          class="addBTN"
                          text
                          small
                          @click="openModal('manager')"
                          >New PM/SC/PE</v-btn
                        >
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="client"
                          :items="clients"
                          :item-value="'id'"
                          item-text="name"
                          menu-props="auto"
                          label="Assign Client"
                          hide-details
                          color="#003790"
                          prepend-icon="mdi-account"
                          single-line
                        ></v-select>
                        <v-btn
                          color="#003790"
                          class="addBTN"
                          text
                          small
                          @click="openModal('client')"
                          >New client</v-btn
                        >
                      </v-col>
                      <v-col cols="12" class="col-account">
                        <div>
                          <v-icon class="v-icon icon icono"
                            >mdi-account-supervisor</v-icon
                          >
                        </div>
                        <div class="label-multiselect">
                          <label class="typo__label">Add tech(s)</label>
                          <multiselect
                            v-model="tech"
                            :options="techs"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            placeholder="Choose tech(s)"
                            label="name"
                            track-by="name"
                            :preselect-first="false"
                          >
                            <template
                              slot="selection"
                              slot-scope="{ values, isOpen }"
                              ><span
                                class="multiselect__single"
                                v-if="values.length &amp;&amp; !isOpen"
                                >{{ values.length }} Techs selected</span
                              ></template
                            >
                          </multiselect>
                          <v-btn
                            color="#003790"
                            class="addBtn-multiselect"
                            text
                            small
                            @click="openModal('tech')"
                            >New tech</v-btn
                          >
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <v-menu
                          ref="dialog"
                          v-model="menu2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateRangeText"
                              label="Date start-end"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="dates" range>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu2 = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dialog.save(dates)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-row>
                        <v-col cols="1">
                          <v-icon class="v-icon icon"
                            >mdi-format-align-justify</v-icon
                          >
                        </v-col>
                        <v-col cols="11">
                          <vue-editor
                            v-model="content"
                            id="editor"
                            name="description"
                          ></vue-editor>
                        </v-col>
                      </v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="statusSet"
                          :items="status_list"
                          item-text="name"
                          :item-value="'id'"
                          label="Status"
                          color="#003790"
                          prepend-icon="mdi-list-status"
                          persistent-hint
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row class="group-btn">
                      <v-btn
                        color="red"
                        text
                        @click="(dialog = false), reset()"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        color="green"
                        text
                        :loading="btnloader"
                        @click="validate()"
                        href="#element"
                      >
                        Save
                      </v-btn>
                    </v-row>
                  </v-container>
                </v-tab-item>
                <v-tab-item name="Project">
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12" class="col-account">
                        <div>
                          <v-icon class="v-icon icon icono"
                            >mdi-google-maps</v-icon
                          >
                        </div>
                        <div class="label-multiselect">
                          <VueGooglePlaces
                            :api-key="apikey"
                            @placechanged="onPlaceChanged"
                            :options="{ fields: ['geometry'] }"
                            country="usa"
                          >
                            <v-text-field
                              label="Enter an an address, zipcode, or location"
                              v-model="address"
                              hide-details="auto"
                            ></v-text-field>
                          </VueGooglePlaces>
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="client"
                          :items="clients"
                          :item-value="'id'"
                          item-text="name"
                          menu-props="auto"
                          label="Assign Client"
                          hide-details
                          color="#003790"
                          prepend-icon="mdi-account"
                          single-line
                        ></v-select>
                        <v-btn
                          color="#003790"
                          class="addBTN"
                          text
                          small
                          @click="openModal('client')"
                          >New client</v-btn
                        >
                      </v-col>
                      <v-row>
                        <v-col cols="1">
                          <v-icon class="v-icon icon"
                            >mdi-format-align-justify</v-icon
                          >
                        </v-col>
                        <v-col cols="11">
                          <vue-editor
                            v-model="content"
                            id="editor"
                            name="description"
                          ></vue-editor>
                        </v-col>
                      </v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="statusSet"
                          :items="status_ticket"
                          item-text="name"
                          :item-value="'id'"
                          label="Status"
                          color="#003790"
                          prepend-icon="mdi-list-status"
                          persistent-hint
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row class="group-btn">
                      <v-btn
                        color="red"
                        text
                        @click="(dialog = false), reset()"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        color="green"
                        text
                        :loading="btnloader"
                        @click="validateProject()"
                        href="#element"
                      >
                        Save
                      </v-btn>
                    </v-row>
                  </v-container>
                </v-tab-item>
              </v-tabs>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <UserModal ref="usermodal" />
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import apis from '@/apis';
import { VueEditor } from 'vue2-editor';
import Multiselect from 'vue-multiselect';
import UserModal from '@/components/UserModal.vue';
export default {
  data: () => ({
    street: null,
    showinput: true,
    apikey: `${process.env.VUE_APP_API_KEY_MAPS}`,
    btnloader: false,
    files: [],
    model: '2Project',
    search: null,
    message: false,
    errors: [],
    name: null,
    po: null,
    so: null,
    address: null,
    manager: [],
    client: null,
    tech: [],
    statusSet: null,
    status_list: [],
    dialog: false,
    dates: [],
    menu2: false,
    body: null,
    content: '<h3>Add description</h3>',
    customToolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  }),
  watch: {
    street() {
      if (this.street) {
        this.showinput = false;
      }
    },
  },
  mounted() {
    this.status_list = this.status;
  },
  methods: {
    ...mapMutations([
      'addService',
      'addDates',
      'addProject',
      'sumProjectActives',
    ]),
    selectedUser(user) {
      switch (user.type) {
        case 'customer':
          this.client = user.id;
          break;
        case 'technician':
          this.tech.push(user);
          break;
        case 'manager':
          this.manager = user.id;
          break;
        default:
          break;
      }
    },
    openModal(option) {
      this.$refs.usermodal.option = option;
      this.$refs.usermodal.dialog = true;
    },
    onPlaceChanged(value) {
      this.address = value.formatted_address;
    },
    reset() {
      this.name = null;
      this.manager = null;
      this.client = null;
      this.po = null;
      this.so = null;
      this.address = null;
      this.dates = [];
      this.content = null;
      this.tech = [];
      this.message = false;
      this.errors = [];
    },
    validateProject() {
      this.errors = [];
      if (!this.name || !this.client) {
        this.message = true;
        if (!this.name) {
          this.errors.push('The project / service name is required. ');
        }
        if (!this.client) {
          this.errors.push('The client is required.');
        }
        if (!this.address) {
          this.errors.push('The address is required.');
        }
      } else {
        this.message = false;
        this.addProjectPost();
      }
    },
    getValidation() {
      if (!this.name) {
        this.errors.push('The project / service name is required. ');
      }
      if (!this.manager) {
        this.errors.push('The manager is required.');
      }
      if (!this.client) {
        this.errors.push('The client is required.');
      }
      if (!this.po) {
        this.errors.push('The po is required.');
      }
      if (!this.address) {
        this.errors.push('The address is required.');
      }
      if (this.dates.length < 2) {
        this.errors.push('Dates start - end are required.');
      }
      if (!this.tech.length > 0) {
        this.errors.push('The tech is required.');
      }
      if (!this.statusSet) {
        this.errors.push('The status is required.');
      }
    },
    validate() {
      this.errors = [];
      if (
        !this.name ||
        !this.manager ||
        !this.client ||
        !this.po ||
        !this.address ||
        this.dates.length < 2 ||
        !this.tech.length > 0 ||
        !this.statusSet
      ) {
        this.message = true;
        this.getValidation();
      } else {
        this.message = false;
        this.addServicePost();
      }
    },
    addServicePost() {
      this.btnloader = true;
      let post = {
        name: this.name,
        manager: this.manager,
        customer: this.client,
        po_purchase: this.po,
        so: this.so,
        address: this.address,
        start: this.dates[0],
        end: this.dates[1],
        description: this.content,
        created_by: this.user.id,
        status_id: this.statusSet,
        techs: this.tech,
      };
      apis
        .addService(post)
        .then(() => {
          this.$parent.message = true;
          this.dialog = false;
          this.btnloader = false;
        })
        .catch((error) => {
          this.btnloader = false;
          this.$swal.fire(
            'Error',
            error.response.data.message,
            'error',
          );
        });
    },

    addProjectPost() {
      this.btnloader = true;
      let post = {
        name: this.name,
        customer: this.client,
        address: this.address,
        description: this.content,
        created_by: this.user.id,
        status_id: this.statusSet,
      };
      apis
        .addProject(post)
        .then(() => {
          this.$parent.message = true;
          this.dialog = false;
          this.btnloader = false;
          this.reset();
        })
        .catch((error) => {
          this.btnloader = false;
          this.$swal.fire(
            'Error',
            error.response.data.message,
            'error',);
        });
    },
  },
  computed: {
    ...mapState([
      'user',
      'techs',
      'clients',
      'managers',
      'status',
      'token',
      'status_project',
      'status_ticket',
      'projects',
    ]),
    dateRangeText() {
      return this.dates.join(' - ');
    },
  },
  components: {
    VueEditor,
    Multiselect,
    UserModal,
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.group-btn {
  justify-content: flex-end;
}
.form-control {
  border-bottom: 1px solid #616161;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.col-account {
  display: flex;
  align-items: center;
  align-content: center;
}
.add-proyect {
  bottom: 12% !important;
  right: 4% !important;
  position: fixed;
}
.headline {
  padding-top: 20px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
.closeBTN {
  position: absolute;
  top: 10px;
  right: 15px;
}
.addBTN {
  margin-left: 32px;
}
.v-text-field {
  padding-top: 10px;
  margin-top: 0px;
}
.icon {
  margin-left: 14px;
  margin-top: 2px !important;
}
.icono {
  margin: 0 !important;
  padding-right: 3px !important;
}
.label-multiselect {
  width: 100%;
}
.addBtn-multiselect {
  margin: 0px !important;
}
.v-btn--fab.v-size--default {
  height: 75px !important;
  width: 75px !important;
}
</style>
