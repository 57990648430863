<template>
  <div class="login">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4" align="center" justify="center">
            <v-img width="70%" src="../assets/img/gjsing-logo-new.png"></v-img>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4" align="center" justify="center"
            >Sign in with your GJ Signs Account</v-col
          >
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="6" md="3" align="center" justify="center">
            <v-card class="elevation-12">
              <v-toolbar color="#081b38" dark flat>
                <v-toolbar-title class="text-login"
                  >Welcome back!</v-toolbar-title
                >
              </v-toolbar>
              <v-card-text>
                <v-expand-transition>
                  <v-alert type="error" v-if="active">
                    {{ msj }}
                  </v-alert>
                </v-expand-transition>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    required
                    v-model="email"
                    label="Login"
                    name="login"
                    :rules="emailRules"
                    prepend-icon="mdi-account"
                    type="text"
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="mdi-lock"
                    v-model="password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passRules"
                    :type="show1 ? 'text' : 'password'"
                    counter
                    @click:append="show1 = !show1"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions class="login">
                <v-btn
                  color="#081b38"
                  class="login-btn"
                  block
                  :loading="loading"
                  :disabled="loading"
                  @click="submit()"
                  >Login</v-btn
                >
              </v-card-actions>
              <SocialLogin />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import SocialLogin from '@/components/SocialLogin';
import apis from '@/apis';
import { mapMutations } from 'vuex';
export default {
  data: () => ({
    show1: false,
    valid: true,
    password: null,
    email: null,
    loading: false,
    msj: null,
    active: false,
    passRules: [
      (v) => !!v || 'Enter password....',
      (v) => (v && v.length >= 6) || 'Password must be more than 6 characters',
    ],
    emailRules: [
      (v) => !!v || 'Enter E-mail....',
      (v) => /.+@.+\..+/.test(v) || 'Invalid E-mail',
    ],
  }),
  components: {
    SocialLogin,
  },
  methods: {
    ...mapMutations(['setToken']),
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let post = {
          email: this.email,
          password: this.password,
        };
        apis
          .loginEmail(post)
          .then((res) => {
            if (res.data.success) {
              localStorage.setItem(
                'user-token',
                res.data.data.token.access_token,
              );
              localStorage.setItem('user', JSON.stringify(res.data.data.user));
              this.setToken({
                token: res.data.data.token.access_token,
                user: res.data.data.user,
              });
              this.$router.push('/');
            } else {
              this.loading = false;
              this.active = true;
              this.msj = res.data.message;
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.active = true;
            this.msj = 'Error Al iniciar sesion';
          });
      }
    },
  },
};
</script>
<style>
.login-btn {
  padding: 20px !important;
}
</style>
