<template>
  <v-card-text style="padding: 0; background: #ffffff;">
    <div v-if="galleriesById.length > 0">
      <LightBox
        ref="lightbox"
        :media="galleriesById"
        :show-caption="true"
        :show-light-box="false"
        :site-loading="siteLoading"
        :disable-zoom="false"
        :show-footer-count="true"
      />
    </div>
    <v-row v-if="loaderPage">
      <v-col md="12" cols="12" class="col-service box">
        <div class="header">
          <h3 class="text-tittle">
            Loading...
          </h3>
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col md="12" cols="12" class="col-service box">
        <div class="header">
          <h3 class="text-tittle">
            {{ serviceSelected.name }}
          </h3>
          <div class="header-btn">
            <v-btn
              @click="closeModal(), (edit = true)"
              elevation="10"
              small
              outlined
              color="white"
              class="btn-modal"
            >
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
            <v-btn
              v-if="user.type === 'superadmin' || user.type === 'admin' || user.type === 'manager'"
              @click="edit = !edit"
              elevation="10"
              small
              outlined
              color="white"
              class="btn-modal-pencil"
            >
              <v-icon color="white">mdi-pencil</v-icon>
            </v-btn>
          </div>
        </div>
        <!-- fin Header -->
        <div v-if="edit">
          <v-row>
            <v-col xs="12" md="12" align-self="center" class="treeview">
              <v-btn-toggle
                color="#081b38"
                v-model="toggle_multiple"
                class="scroll"
              >
                <v-btn depressed v-for="(fol, i) in folders" :key="i">
                  <v-icon color="#081b38">
                    {{
                      toggle_multiple == i ? 'mdi-folder-open' : 'mdi-folder'
                    }}
                  </v-icon>
                  {{ fol.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col></v-row
          >

          <!-- galeria mobile -->
          <div class="divisor d-flex d-sm-none">
            <div style="width: 100%;">
              <v-carousel hide-delimiters height="150" style="height: 150px;">
                <v-carousel-item
                  v-if="!showGalery"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                >
                  <UploadFull
                    type="service"
                    @activeShowGalery="activeShowGalery"
                  />
                </v-carousel-item>
                <v-carousel-item
                  v-for="(item, i) in galleriesById"
                  :key="i"
                  @click="openGallery(item.id)"
                  :src="item.thumb"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                  style="background-size: cover; background-position: center;     height: 150px;"
                ></v-carousel-item>
              </v-carousel>

              <v-btn
                v-if="showGalery"
                color="#081b38"
                dark
                style="margin: 5px"
                @click="dialog = true"
              >
                <p class="p-u">Upload Image</p>
                <v-icon color="white">mdi-cloud-upload</v-icon>
              </v-btn>
              <div class="text-center">
                <v-dialog v-model="dialog">
                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      Upload File
                    </v-card-title>

                    <UploadFull
                      type="service"
                      @activeShowGalery="activeShowGalery"
                    />
                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="dialog = false">
                        close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </div>
          </div>

          <!-- fin mobile -->
          <!-- galeria -->

          <div class="divisor d-none d-sm-flex">
            <div class="gallery">
              <v-carousel
                v-if="showGalery"
                v-model="model"
                height="300"
                :continuous="false"
                hide-delimiters
              >
                <v-carousel-item v-for="(color, i) in parse" :key="i">
                  <v-sheet height="100%" tile>
                    <v-row class="fill-height" align="center" justify="center">
                      <div class="grind">
                        <div class="test" v-if="i == 0">
                          <div class="gallery-img">
                            <UploadFull type="service" />
                          </div>
                        </div>
                        <div class="test" v-for="(img, x) in color" :key="x">
                          <div
                            @click="openGallery(img.id)"
                            class="gallery-img"
                            :style="
                              `background-image: url(${img.thumb}); background-size: cover; background-position: center; padding: 2px; margin: 2px; width: 100%; display: flex; justify-content: space-between; flex-direction: column;`
                            "
                          >
                            <div class="div-btn-edit">
                              <v-btn
                                v-if="!img.selected"
                                icon
                                dark
                                class="check"
                                @click="
                                  (img.selected = !img.selected), selectImg(img)
                                "
                              >
                                <v-icon color="primary">mdi-check-bold</v-icon>
                              </v-btn>
                              <v-btn
                                v-else
                                icon
                                dark
                                class="check-t"
                                @click="
                                  (img.selected = !img.selected), selectImg(img)
                                "
                              >
                                <v-icon color="primary">mdi-check-bold</v-icon>
                              </v-btn>
                            </div>
                            <div class="div-btn-edit">
                              <v-btn
                                icon
                                dark
                                class="trash"
                                @click="deleteMultimedia(img.id)"
                              >
                                <v-icon color="red">mdi-delete</v-icon>
                              </v-btn>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-row>
                  </v-sheet>
                </v-carousel-item>
              </v-carousel>
              <v-carousel
                v-else
                v-model="model"
                height="300"
                :continuous="false"
                hide-delimiters
              >
                <v-carousel-item>
                  <v-sheet height="100%" tile>
                    <v-row class="fill-height" align="center" justify="center">
                      <div class="grind">
                        <div class="test">
                          <div class="gallery-img">
                            <UploadFull
                              type="service"
                              @activeShowGalery="activeShowGalery"
                            />
                          </div>
                        </div>
                      </div>
                    </v-row>
                  </v-sheet>
                </v-carousel-item>
              </v-carousel>
            </div>
          </div>
          <div class="group-btn-p  d-none d-sm-flex">
            <v-btn
              class="btn-p"
              v-if="selected.length > 0"
              :loading="loadingImgs"
              color="red"
              @click="deleteImagesSelected()"
            >
              <v-icon color="white" class="note-p">mdi-delete</v-icon>
              Delete {{ selected.length }} Pictures Selected
            </v-btn>
            <v-btn
              class="btn-p"
              :loading="loading"
              color="#081b38"
              @click="downloadAllimages()"
            >
              <v-icon color="white" class="note-p">mdi-download</v-icon>
              Download Pictures
            </v-btn>
            <v-btn
              class="btn-p"
              v-if="selected.length > 0"
              :loading="loading"
              color="#081b38"
              @click="downloadImagesSelected()"
            >
              <v-icon color="white" class="note-p">mdi-download</v-icon>
              Download {{ selected.length }} Pictures Selected
            </v-btn>
          </div>
          <!-- fingaleria -->
          <!-- conyainer  -->
          <div>
            <v-row style="margin: 15px">
              <v-col md="6" cols="12">
                <v-hover v-slot="{ hover }" open-delay="200">
                  <v-card
                    :elevation="hover ? 16 : 2"
                    :class="{ 'on-hover': hover }"
                  >
                    <v-list-item three-line class="d-none d-sm-flex">
                      <p class="text-card">
                        Project: #{{ serviceSelected.id }}
                      </p>
                      <p class="text-card">
                        PO: #{{ serviceSelected.po_purchase }}
                      </p>
                      <p class="text-card">SO: #{{ serviceSelected.so }}</p>
                      <div>
                        <v-select
                          v-model="statusSet"
                          :items="statusList"
                          item-text="name"
                          :item-value="'id'"
                          color="green"
                          class="icon-color"
                          prepend-icon="mdi-brightness-1 "
                          persistent-hint
                          @click="statusEnable = true"
                        ></v-select>
                      </div>
                    </v-list-item>
                    <v-list-item
                      three-line
                      style="display: flex; height: 1px; flex-direction: column;"
                      class="d-flex d-sm-none"
                    >
                      <div class="mobile-div">
                        <p class="text-card">
                          Project: #{{ serviceSelected.id }}
                        </p>
                        <p class="text-card">
                          PO: #{{ serviceSelected.po_purchase }}
                        </p>
                      </div>
                      <div class="mobile-div">
                        <p class="text-card">SO: #{{ serviceSelected.so }}</p>
                        <v-select
                          v-model="statusSet"
                          :items="statusList"
                          item-text="name"
                          :item-value="'id'"
                          color="green"
                          class="icon-color"
                          prepend-icon="mdi-brightness-1 "
                          persistent-hint
                          @click="statusEnable = true"
                        ></v-select>
                      </div>
                    </v-list-item>

                    <div class="combo statusEnable" v-if="statusEnable">
                      <v-alert v-if="error" dense type="error">
                        Note Required
                      </v-alert>
                      <br />
                      <div class="combo noteStatusSimple"></div>

                      <div class="combo noteStatus">
                        <v-textarea v-model="noteStatus" color="teal">
                          <template v-slot:label>
                            <div>Note <small>(required)</small></div>
                          </template>
                        </v-textarea>
                      </div>
                      <div class="frogger">
                        <v-btn
                          elevation="11"
                          medium
                          color="error"
                          @click="
                            (statusEnable = false),
                              (statusSet = statusget),
                              (history = false)
                          "
                          class="btn-padd"
                          >cancel</v-btn
                        >
                        <v-btn
                          elevation="11"
                          class="btn-padd"
                          medium
                          color="success"
                          :loading="deleteLoader"
                          @click="changeStatus()"
                          >Send</v-btn
                        >
                      </div>
                    </div>
                    <br />
                    <v-list-item v-if="serviceSelected.eventLink !== null">
                      <div class="text-overline w-100 text-center">
                        <a class="proyect" :href="serviceSelected.eventLink" target="_blank" rel="noopener noreferrer">
                          Go to Calendar
                        </a>
                      </div>
                    </v-list-item>
                    <v-list-item>
                      <div class="text-overline mb-4">
                        Scope of Work:
                      </div>
                    </v-list-item>
                    <v-list-item>
                      <p v-html="serviceSelected.description" />
                    </v-list-item>

                    <v-list-item>
                      <div class="text-overline mb-4">
                        Paperwork:
                      </div>
                    </v-list-item>
                    <v-list-item>
                      <div style="width: 100%;">
                        <v-chip
                          style="width: 30%;"
                          v-for="(docs, i) in serviceSelected.docs"
                          :key="i"
                          class="ma-2"
                          color="red"
                          text-color="white"
                          close
                          @click="sendUrl(docs.url)"
                          @click:close="
                            deleteMultimedia(docs.id), (pdfURL = docs.url)
                          "
                        >
                          <v-icon left>
                            mdi-pdf-box
                          </v-icon>
                          <p class="p-document">{{ getPdfName(docs.data) }}</p>
                        </v-chip>
                      </div>
                    </v-list-item>

                    <v-list-item class="d-none d-sm-flex">
                      <gmap-map
                        :center="center"
                        :zoom="16"
                        style="width: 90%; height: 350px; left: 0; right: 0; margin-left: auto; margin-right: auto;"
                      >
                        <gmap-marker
                          :key="index"
                          v-for="(m, index) in markers"
                          :position="m.position"
                          :title="m.title"
                          :clickable="true"
                          :draggable="true"
                          @click="center = m.position"
                        ></gmap-marker>
                      </gmap-map>
                    </v-list-item>
                    <v-list-item class="div-direction">
                      <h3 class="direction" @click="goMap()">
                        {{ serviceSelected.address }}
                      </h3></v-list-item
                    >
                  </v-card>
                </v-hover>
                <br />
                <v-hover
                  v-slot="{ hover }"
                  open-delay="200"
                  class="d-flex d-sm-none"
                >
                  <v-card
                    :elevation="hover ? 16 : 2"
                    :class="{ 'on-hover': hover }"
                  >
                    <div class="project-color" style="width: 100%;">
                      <p class="proyect">
                        Notes
                        <v-icon color="#182a45">mdi-comment-text </v-icon>
                      </p>

                      <v-expand-transition>
                        <div class="note-div">
                          <v-btn
                            @click="modalNote = true"
                            class="note"
                            color="#081b38"
                          >
                            <v-icon color="white" class="note"
                              >mdi-comment-quote</v-icon
                            >
                            New Note
                          </v-btn>
                          <div
                            v-for="(note, i) in serviceSelected.notes"
                            :key="i"
                            class="note-acount"
                          >
                            <v-avatar
                              size="26px"
                              @click="viewNote(note), (addNote = true)"
                            >
                              <img alt="Avatar" :src="note.user.avatar" />
                            </v-avatar>
                            <p
                              class="name-note"
                              @click="viewNote(note), (addNote = true)"
                            >
                              {{ note.user.name }}
                            </p>
                            <p
                              @click="viewNote(note), (addNote = true)"
                              v-html="note.note"
                              class="name-note"
                            ></p>

                            <v-btn icon dark @click="deleteNote(note.id)">
                              <v-icon color="red">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </v-expand-transition>
                      <v-dialog v-model="modalNote" persistent max-width="600">
                        <v-card>
                          <v-card-text>
                            <v-alert v-show="noterror" type="error">{{
                              labelnote
                            }}</v-alert>
                            <vue-editor
                              v-model="note"
                              id="editorTerm"
                              name="description"
                            ></vue-editor>
                            <v-spacer></v-spacer>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="#081b38"
                              text
                              @click="modalNote = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              color="#081b38"
                              text
                              :loading="deleteLoader"
                              @click="setNote()"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                    <br />
                  </v-card>
                </v-hover>
              </v-col>
              <v-col md="6" cols="12">
                <v-hover
                  v-slot="{ hover }"
                  open-delay="200"
                  class="d-none d-sm-flex"
                >
                  <v-card
                    :elevation="hover ? 16 : 2"
                    :class="{ 'on-hover': hover }"
                  >
                    <div class="project-color" style="width: 100%;">
                      <p class="proyect">
                        Notes
                        <v-icon color="#182a45">mdi-comment-text </v-icon>
                      </p>

                      <v-expand-transition>
                        <div class="note-div">
                          <v-btn
                            @click="modalNote = true"
                            class="note"
                            color="#081b38"
                          >
                            <v-icon color="white" class="note"
                              >mdi-comment-quote</v-icon
                            >
                            New Note
                          </v-btn>
                          <div
                            v-for="(note, i) in serviceSelected.notes"
                            :key="i"
                            class="note-acount"
                          >
                            <div class="notes-profile">
                              <v-avatar
                                size="26px"
                                @click="viewNote(note), (addNote = true)"
                              >
                                <img alt="Avatar" :src="note.user.avatar" />
                              </v-avatar>
                              <p
                                class="name-note"
                                @click="viewNote(note), (addNote = true)"
                              >
                                {{ note.user.name }}
                              </p>
                            </div>
                            <div class="notes-text">
                              <p
                                @click="viewNote(note), (addNote = true)"
                                v-html="note.note"
                                class="name-note"
                              ></p>
                            </div>
                            <div>
                              <v-btn icon dark @click="deleteNote(note.id)">
                                <v-icon color="red">mdi-delete</v-icon>
                              </v-btn>
                            </div>
                          </div>
                        </div>
                      </v-expand-transition>
                    </div>
                    <br />
                  </v-card>
                </v-hover>

                <br />
                <Weather ref="weather" />
                <br />
                <v-hover
                  v-slot="{ hover }"
                  open-delay="200"
                  v-if="serviceSelected.users"
                >
                  <v-card
                    :elevation="hover ? 16 : 2"
                    :class="{ 'on-hover': hover }"
                  >
                    <div class="project-color">
                      <p class="proyect">
                        <v-icon color="#182a45"
                          >mdi-account-box-multiple</v-icon
                        >
                        Project Members
                      </p>
                      <v-expand-transition>
                        <div class="proyect-members">
                          <div
                            @click="
                              modalUser(serviceSelected.manager),
                                (modalAcount = true)
                            "
                          >
                            <p class="proyect-label">
                              Project Manager/Service Coordinator
                            </p>
                            <div class="google-acount">
                              <v-avatar size="36px">
                                <img
                                  alt="Avatar"
                                  :src="serviceSelected.manager.avatar"
                                />
                              </v-avatar>
                              <p class="name-acount">
                                {{ serviceSelected.manager.name }}
                              </p>
                            </div>
                          </div>
                          <div
                            @click="
                              modalUser(serviceSelected.customer),
                                (modalAcount = true)
                            "
                          >
                            <p class="proyect-label">
                              Client
                            </p>
                            <div class="google-acount">
                              <v-avatar size="36px">
                                <img
                                  alt="Avatar"
                                  :src="serviceSelected.customer.avatar"
                                />
                              </v-avatar>
                              <p class="name-acount">
                                {{ serviceSelected.customer.name }}
                              </p>
                            </div>
                          </div>
                          <div>
                            <p class="proyect-label">
                              Technician(s)
                            </p>
                            <div
                              class="google-acount"
                              v-for="(tech, i) in serviceSelected.users"
                              :key="i"
                              @click="modalUser(tech), (modalAcount = true)"
                            >
                              <v-avatar size="36px">
                                <img alt="Avatar" :src="tech.avatar" />
                              </v-avatar>
                              <p class="name-acount">{{ tech.name }}</p>
                            </div>
                          </div>
                        </div>
                      </v-expand-transition>
                    </div>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-hover v-slot="{ hover }" open-delay="200">
                  <v-card
                    :elevation="hover ? 16 : 2"
                    :class="{ 'on-hover': hover }"
                  >
                    <div class="calendar">
                      <p class="address">
                        <img
                          alt="Calendar"
                          src="https://app.gjsigns.net/images/calendar.jpg"
                          class="img-address"
                        />Calendar
                      </p>
                      <v-sheet tile height="54" class="d-flex">
                        <v-btn icon class="ma-2" @click="prev()">
                          <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-spacer class="date" style="text-align: center;">
                          <v-toolbar-title v-if="$refs.calendario">
                            {{ $refs.calendario.title }}
                          </v-toolbar-title>
                        </v-spacer>
                        <v-btn icon class="ma-2" @click="next()">
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-sheet>
                      <v-sheet
                        v-if="activate"
                        style="margin: 30px; padding-bottom: 25px;"
                      >
                        <v-calendar
                          ref="calendario"
                          v-model="today"
                          color="primary"
                          type="month"
                          :now="today"
                          :events="dates"
                        ></v-calendar>
                      </v-sheet>
                    </div>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-hover v-slot="{ hover }" open-delay="200">
                  <v-card
                    :elevation="hover ? 16 : 2"
                    :class="{ 'on-hover': hover }"
                  >
                    <div class="attachments">
                      <p class="proyect">
                        Project Logs

                        <v-btn
                          v-if="!expandLogs"
                          @click="expandLogs = !expandLogs"
                          elevation="2"
                          icon
                          class="btn-dismis"
                        >
                          <v-icon dark color="#081b38">
                            mdi-plus
                          </v-icon>
                        </v-btn>
                        <v-btn
                          v-else
                          @click="expandLogs = !expandLogs"
                          elevation="2"
                          icon
                          class="btn-dismis"
                        >
                          <v-icon dark color="#081b38">
                            mdi-minus
                          </v-icon>
                        </v-btn>
                      </p>
                      <v-expand-transition v-if="expandLogs">
                        <div style="padding: 30px;">
                          <div v-if="statusHistory.length > 0">
                            <v-timeline reverse dense>
                              <v-timeline-item
                                v-for="(history,
                                n) in statusHistory"
                                :key="n"
                                small
                                fill-dot
                                color="#182a45"
                              >
                                <v-card class="elevation-2" height="100%">
                                  <v-card-title class="log-name">
                                    <small>
                                      {{ history.value.split('"')[3] }}
                                    </small>
                                  </v-card-title>
                                  <v-card-text class="log-tex">
                                    <p class="log-description">
                                      <strong>Description: </strong>
                                      {{ history.value.split('"')[7] }}
                                    </p>
                                    <p>
                                      <strong>
                                        Message:
                                      </strong>
                                      {{ history.value.split('"')[11] }}
                                    </p>
                                    <br />
                                    {{ date(history.created_at) }}
                                  </v-card-text>
                                </v-card>
                              </v-timeline-item>
                            </v-timeline>
                          </div>
                          <div v-else>
                            <v-col md="12" cols="12">
                              <div>
                                <h3>
                                  Loading...
                                </h3>
                              </div>
                            </v-col>
                          </div>
                        </div>
                      </v-expand-transition>
                    </div>
                  </v-card></v-hover
                >
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-else><Edit :data="serviceSelected" @update="updService" /></div>
      </v-col>
    </v-row>

    <v-dialog v-model="modalAcount" persistent max-width="600">
      <v-card>
        <v-card-text>
          <div class="modal-card">
            <v-btn icon dark @click="modalAcount = false" class="close-data">
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
            <v-avatar size="66px">
              <img alt="Avatar" :src="avatar_card" />
            </v-avatar>
            <p class="name-card">{{ name_card }}</p>
          </div>
          <div>
            <h4>Contact Detais</h4>
            <p class="manager_cart">
              <v-icon color="#182a45" style="padding-right: 10px;"
                >mdi-city</v-icon
              >
              {{ type_card }}
            </p>
            <p class="manager_cart">
              <v-icon color="#182a45" style="padding-right: 10px;"
                >mdi-email</v-icon
              >
              <a :href="`mailto:${mail_card}`" class="account-a">{{
                mail_card
              }}</a>
            </p>
            <p class="manager_cart">
              <v-icon color="#182a45" style="padding-right: 10px;"
                >mdi-phone</v-icon
              >
              <a :href="`tel:+${phoneCard}`" class="account-a"
                >{{ phoneCard }} </a
              ><small>(mobile)</small>
            </p>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addNote" persistent max-width="600">
      <v-card style="background: white">
        <v-card-text>
          <div class="note-modal">
            <v-avatar size="46px" class="note-modal-img">
              <img alt="Avatar" :src="note_avatar" />
            </v-avatar>
            <p class="note-modal-name">{{ note_name }}</p>
            <v-spacer></v-spacer>
            <p class="note-modal-date">{{ dateFormat() }}</p>
          </div>
          <div>
            <p class="modal-note-text" v-html="note_description"></p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="addNote = false">
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card-text>
</template>
<script>
require('vue-image-lightbox/dist/vue-image-lightbox.min.css');
import { mapState, mapMutations } from 'vuex';
import axios from 'axios';
import apis from '@/apis';
import UploadFull from '@/components/UploadGrid';
import moment from 'moment';
import Edit from '@/components/EditProyect.vue';
import LightBox from 'vue-image-lightbox';
import { VueEditor } from 'vue2-editor';
import Weather from '@/components/WeatherFull.vue';
import siteLoading from 'vue-image-lightbox/src/siteloading.gif';
export default {
  data() {
    return {
      dialog: false,
      modalAcount: false,
      showGalery: false,
      avatar_card: '',
      name_card: '',
      expandLogs: false,
      type_card: '',
      mail_card: '',
      phoneCard: '',
      addNote: false,
      note_name: '',
      note_avatar: '',
      note_description: '',
      selectEstimate: [],
      note_date: '',
      note: '',
      noterror: false,
      modalNote: false,
      labelnote: 'hola',
      deleteLoader: false,
      edit: true,
      activate: true,
      statusget: null,
      galleriesById: [],
      siteLoading,
      toggle_multiple: 0,
      parse: [],
      deleteNoteDialog: false,
      loadingImgs: false,
      loading: false,
      statusSet: null,
      after: [],
      imgs: [],
      init: false,
      selected: [],
      model: 0,
      error: false,
      before: [],
      uncategorized: [],
      statusEnable: false,
      noteStatus: null,
      today: '2021-06-21 10:00',
      center: { lat: 20.0396296, lng: -100.7298147 },
      markers: [],
      folders: [
        {
          name: 'uncategorized',
          id: '0',
        },
        {
          name: 'before',
          id: '1',
        },
        {
          name: 'after',
          id: '2',
        },
      ],
      statusList: [],
      loaderPage: true,
    };
  },
  computed: {
    ...mapState([
      'user',
      'status',
      'token',
      'lat',
      'modalProyect',
      'lng',
      'dates',
      'serviceSelected',
      'statusHistory',
    ]),
  },
  mounted() {
    this.inizialize();
    this.setStatusHistory([]);
  },
  components: {
    UploadFull,
    LightBox,
    VueEditor,
    Weather,
    Edit,
  },
  methods: {
    ...mapMutations(['setService', 'setLabel', 'setStatus', 'updateService', 'setStatusHistory']),

    updService() {
      this.edit = true;
    },
    selectImg(img) {
      setTimeout(() => {
        this.$refs.lightbox.closeLightBox();
      }, 1);
      if (img.selected) {
        this.selected.push(img.id);
      } else {
        const index = this.selected.findIndex((id) => id === img.id);
        if (index !== -1) {
          this.selected.splice(index, 1);
        }
      }
    },
    date(date) {
      return moment(date).format('LLLL');
    },
    deleteEstimate(idEstimate) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: 'You wont be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            apis.getUrl(`/estimates/${idEstimate}`).then((data) => {
              const index = this.serviceSelected.estimates.findIndex(
                ({ id }) => id === idEstimate,
              );
              if (index !== -1) {
                this.serviceSelected.estimates.splice(index, 1);
              }
              this.serviceSelected.statusHistory.unshift(data.data.log);
              this.$swal.fire('Estimate Deleted!', '', 'success');
            });
          }
        });
    },
    activeShowGalery() {
      if (this.parse.length >= 1) {
        this.showGalery = true;
      } else {
        this.showGalery = false;
      }
      this.dialog = false;
    },
    newEstimate() {
      this.selectEstimate = [];
    },
    modalEstimate(estimate) {
      this.selectEstimate = estimate;
      this.$refs.estimateModal.dialog = true;
    },
    estimatePdf(id) {
      window.open(`${process.env.VUE_APP_BASE_URL_API}/pdf/${id}`, '_blank');
    },
    modalUser(user) {
      this.avatar_card = user.avatar;
      this.name_card = user.name;
      this.type_card = user.type;
      this.mail_card = user.email;
      this.phoneCard = user.phone;
    },
    deleteNote(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: 'You wont be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            apis.sendDelete(`/notes/${id}`).then(() => {
              this.$swal.fire('Note Deleted!', '', 'success');
              this.deleteNoteDialog = false;
              this.deleteLoader = false;
            });
          }
        });
    },
    dateFormat() {
      return moment(this.note_date).format('MM-DD-YYYY hh:mm');
    },
    viewNote(note) {
      this.note_name = note.user.name;
      this.note_avatar = note.user.avatar;
      this.note_description = note.note;
      this.note_date = note.created_at;
    },
    setNote() {
      if (this.note === null || this.note === '') {
        this.labelnote = 'Add note (note is required)';
        this.noterror = true;
      } else {
        this.deleteLoader = true;
        let post = {
          note: this.note,
          color: 'red',
          service_id: this.serviceSelected.id,
          user_id: this.user.id,
        };
        apis.sendPost('/notes', post).then(() => {
          this.$swal.fire('Note Saved!', '', 'success');
          this.labelnote = 'Add note...';
          this.noterror = false;
          this.note = ' ';
          this.deleteLoader = false;
          this.modalNote = false;
        });
      }
    },
    prev() {
      this.$refs.calendario.prev();
    },
    next() {
      this.$refs.calendario.next();
    },
    getPdfName(data) {
      const name = JSON.parse(data);
      return name.name;
    },
    goMap() {
      window.open(
        `https://www.google.com/maps/place/${this.serviceSelected.address}`,
        '_blank',
      );
    },
    updateEstimate(data) {
      const index = this.serviceSelected.estimates.findIndex(
        ({ id }) => id === data.estimate.id,
      );
      if (index !== -1) {
        this.serviceSelected.estimates.splice(index, 1, data.estimate);
      } else {
        this.serviceSelected.estimates.unshift(data.estimate);
      }
      this.serviceSelected.statusHistory.unshift(data.log);
    },
    changeStatus() {
      this.deleteLoader = true;
      if (this.noteStatus === null) {
        this.error = true;
        this.deleteLoader = false;
      } else {
        this.error = false;
        let formData = new FormData();
        formData.append('statusId', this.statusSet);
        formData.append('service_id', this.serviceSelected.id);
        formData.append('message', this.noteStatus);
        axios
          .post(`${process.env.VUE_APP_BASE_URL_API}/api/v1/status`, formData, {
            headers: {
              Authorization: `Bearer ${this.token}`,
              'Content-Type': 'multipart/form-data',
              'X-Requested-With': 'XMLHttpRequest',
              Accept: 'application/json',
            },
          })
          .then(() => {
            this.deleteLoader = false;
            this.$swal.fire('Status Saved!', '', 'success');
            this.statusEnable = false;
            this.noteStatus = null;
            this.history = false;
            this.statusget = this.statusSet;
          })
          .catch((error) => {
            this.deleteLoader = false;
            this.$swal.fire('Error!', error, 'error');
          });
      }
    },
    downloadImagesSelected() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 5000);
      window.open(
        `${process.env.VUE_APP_BASE_URL_API}/downloadImgSelected/${this.serviceSelected.id}/${this.selected}`,
        '_blank',
      );

      this.uncategorized = [];
      this.after = [];
      this.before = [];
      this.getImagesbyLabels();
      this.imageChunk();
    },
    deleteImagesSelected() {
      this.loadingImgs = true;
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: 'You wont be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            let post = {
              ids: this.selected,
            };
            apis.sendPost('/multimedias/deleteImgs', post).then(() => {
              this.$swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success',
              );
              this.selected = [];
            });
          }
          this.loadingImgs = false;
        });
    },
    async downloadAllimages() {
      this.loading = true;
      this.$swal.fire({
        title: 'Procesando Imagenes',
        html: 'Se cerrará esta ventana cuando finalice.',
        timerProgressBar: true,
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      const url = `${process.env.VUE_APP_BASE_URL_API}/api/v1/downloadAllimg/${this.serviceSelected.id}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: 'application/json',
        },
      });
      this.loading = false;
      this.$swal.close();
      this.$swal.fire(`${response.data.msg}`, '', 'success');
    },

    sendUrl(url) {
      if (url.includes('drive.google.com')) {
        window.open(url, '_blank');
      } else {
        window.open(
          'https://systemgj.s3-us-west-1.amazonaws.com/' + url,
          '_blank',
        );
      }
    },
    closeModal() {
      this.$router.push('/services');
    },
    inizialize() {
      this.loaderPage = true;
      apis.getUrl(`/service/${this.$route.params.id}`).then((reponse) => {
        this.setService(reponse.data.data);
        this.init = true;
        this.loaderPage = false;
        this.statusSet = this.serviceSelected.status;

        this.today = this.serviceSelected.start;
        this.statusList = this.status;

        this.setLabel('uncategorized');
        this.today = this.serviceSelected.start;
        this.center = { lat: this.lat, lng: this.lng };
        this.markers = [
          {
            position: { lat: this.lat, lng: this.lng },
            title: reponse.data.data.name,
          },
        ];
        this.statusSet = this.serviceSelected.status;
        this.statusList = this.status;
        const chunks = [];
        let i = 0;
        let x = 0;
        this.getImagesbyLabels();

        const n = this.uncategorized.length;
        this.imgs = this.uncategorized;
        while (i < n) {
          if (i == 0) {
            x = i + 7;
            chunks.push(this.imgs.slice(i, x));
            i = i + 7;
          } else {
            x = i + 8;
            chunks.push(this.imgs.slice(i, x));
            i = i + 8;
          }
        }
        this.parse = chunks;

        if (this.parse.length >= 1) {
          this.showGalery = true;
        } else {
          this.showGalery = false;
        }
        this.galleriesById = this.getGalleryLigthbox([...this.imgs]);
        this.toggle_multiple = 0;
        this.statusget = this.serviceSelected.status_id;
      });
    },

    deleteMultimedia(id) {
      setTimeout(() => {
        this.$refs.lightbox.closeLightBox();
      }, 20);
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: 'You wont be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            apis.sendDelete(`/multimedias/${id}`).then(() => {
              this.$swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success',
              );
            });
          }
        });
    },

    imageChunk() {
      switch (this.toggle_multiple) {
        case 0:
          this.imgs = this.uncategorized;
          break;
        case 1:
          this.imgs = this.before;
          break;
        case 2:
          this.imgs = this.after;
          break;
        default:
          break;
      }
      const chunks = [];
      let i = 0;
      let x = 0;
      const n = this.imgs.length;
      while (i < n) {
        if (i == 0) {
          x = i + 7;
          chunks.push(this.imgs.slice(i, x));
          i = i + 7;
        } else {
          x = i + 8;
          chunks.push(this.imgs.slice(i, x));
          i = i + 8;
        }
      }
      this.parse = chunks;
      this.galleriesById = this.getGalleryLigthbox([...this.imgs]);
      if (this.parse.length >= 1) {
        this.$refs.lightbox.showImage(0);
        this.$refs.lightbox.closeLightBox();
      }
      this.loadingImgs = false;
      this.selected = [];
    },
    getGalleryLigthbox(galleries) {
      if (!galleries) return [];
      return galleries.map((gallery) => {
        return {
          id: gallery.id,
          selected: gallery.selected,
          thumb: gallery.thumb,
          src: gallery.src,
          caption: gallery.caption,
        };
      });
    },
    openGallery(index) {
      const indexOld = this.galleriesById.findIndex(({ id }) => id === index);
      this.$refs.lightbox.showImage(indexOld);
    },
    getImagesbyLabels() {
      this.uncategorized = [];
      this.after = [];
      this.before = [];
      this.serviceSelected.images.map((img) => {
        let imgParse = {
          selected: false,
          id: img.id,
          thumb: `https://systemgj.s3-us-west-1.amazonaws.com/${img.data.thumb}`,
          src: `https://systemgj.s3-us-west-1.amazonaws.com/${img.url}`,
          caption: `<small>Image uploaded by ${img.user.name} at ${moment(
            img.updated,
          ).format('MM-DD-YYYY hh:mm')}</small>`,
        };
        switch (img.label) {
          case 'Uncategorized':
          case 'uncategorized':
            this.uncategorized.push(imgParse);
            break;
          case 'After':
          case 'after':
            this.after.push(imgParse);
            break;
          case 'Before':
          case 'before':
            this.before.push(imgParse);
            break;

          default:
            break;
        }
      });
    },
  },
  watch: {
    expandLogs() {
      if (this.expandLogs) {
        apis.logsService(this.serviceSelected.id).then((data) => {
          this.setStatusHistory(data.data.statusHistory);
            });
      }
    },
    serviceSelected() {
      this.statusSet = this.serviceSelected.status;

      this.uncategorized = [];
      this.after = [];
      this.before = [];
      this.img = [];
      this.parse = [];
      const chunks = [];

      let i = 0;
      let x = 0;
      this.getImagesbyLabels();

      let n;
      switch (this.toggle_multiple) {
        case 0:
          n = this.uncategorized.length;
          this.imgs = this.uncategorized;
          break;
        case 1:
          n = this.before.length;
          this.imgs = this.before;
          break;
        case 2:
          n = this.after.length;
          this.imgs = this.after;
          break;
        default:
          break;
      }
      while (i < n) {
        if (i == 0) {
          x = i + 7;
          chunks.push(this.imgs.slice(i, x));
          i = i + 7;
        } else {
          x = i + 8;
          chunks.push(this.imgs.slice(i, x));
          i = i + 8;
        }
      }
      this.parse = chunks;
      this.galleriesById = this.getGalleryLigthbox([...this.imgs]);
    },
    parse() {
      if (this.parse.length == 0) {
        this.showGalery = false;
      }
    },
    toggle_multiple() {
      if (this.$refs.lightbox) {
        this.$refs.lightbox.showImage(0);
        this.$refs.lightbox.closeLightBox();
      }
      let label = '';
      switch (this.toggle_multiple) {
        case 0:
          label = 'uncategorized';
          this.imgs = this.uncategorized;
          break;
        case 1:
          label = 'before';
          this.imgs = this.before;
          break;
        case 2:
          label = 'after';
          this.imgs = this.after;
          break;
        default:
          break;
      }
      const chunks = [];
      let i = 0;
      let x = 0;
      const n = this.imgs.length;
      while (i < n) {
        if (i == 0) {
          x = i + 7;
          chunks.push(this.imgs.slice(i, x));
          i = i + 7;
        } else {
          x = i + 8;
          chunks.push(this.imgs.slice(i, x));
          i = i + 8;
        }
      }
      this.parse = chunks;

      if (this.parse.length >= 1) {
        this.showGalery = true;
      } else {
        this.showGalery = false;
      }
      this.galleriesById = this.getGalleryLigthbox([...this.imgs]);
      this.setLabel(label);
    },
  },
};
</script>
<style>
.mobile-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  align-content: center;
}
.btn-p {
  color: white !important;
}
.group-btn-p {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}
.div-btn-edit {
  display: flex;
}
.v-item--active .v-icon {
  color: #081b38 !important;
}
.treeview {
  display: flex;
  justify-content: center;
}
.mdi-brightness-1 {
  color: green !important;
}
.address {
  text-align: center;
  font-size: 25px;
  margin: 0 !important;
  padding: 20px;
}
.p-u {
  margin-bottom: 0 !important;
  color: white;
  padding: 5px;
}
.address-location {
  text-align: center;
  font-size: 18px;
  margin: 0 !important;
  margin-bottom: 20px !important;
}
.calendar {
  margin-top: 30px;
  color: #3a3a3a;
}
.modal-note-text {
  margin-top: 15px;
  color: #000;
  font-size: 26px;
  height: 350px;
  line-height: 1.6;
  overflow: auto;
}
.note-modal-img {
  margin: 15px;
}

.note-modal-date {
  margin: 0 !important;
  color: black;
  font-size: 12px;
  font-style: italic;
}
.note-modal-name {
  margin: 0 !important;
  color: black;
  font-size: 20px;
}
.note-modal {
  display: flex;
  align-items: center;
  align-content: center;
}
.account-a {
  text-decoration: none;
}
.manager_cart {
  padding: 3px;
  font-size: 13px;
  font-weight: 500;
}
.p-document {
  margin-bottom: 0 !important;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.name-card {
  margin: 0px;
  padding: 19px;
  font-weight: bold;
  font-size: large;
  color: black;
}
.close-data {
  left: 94%;
  top: 0;
  position: absolute;
}
.proyect-members {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 25px;
  padding-bottom: 25px;
  flex-wrap: wrap;
}
.modal-card {
  display: flex;
  padding: 35px 0px;
  border-bottom: 1px solid grey;
}
.proyect-label {
  font-size: 17px;
  padding-left: 5px;
  margin-bottom: 2px !important;
  padding-top: 10px;
}
.name-acount {
  margin: 0px !important;
  font-size: large;
  padding-left: 10px;
  font-weight: 900;
}
.google-acount {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  padding: 6px;
  cursor: pointer;
}
.proyect {
  text-align: center;
  font-size: 25px;
  padding: 15px;
  margin-bottom: 0px !important;
}
.note-div {
  text-align: center;
  padding-bottom: 20px;
}
.note-p {
  text-align: center;
  color: white !important;
  padding: 10px !important;
  border-radius: 20px;
}
.note {
  text-align: center;
  color: white !important;
  padding: 10px !important;
  border-radius: 20px;
}
.name-note {
  margin-bottom: 0px !important;
}
.note-acount {
  padding: 5px;
  margin: 5px;
  display: flex;
  background: #ffffff;
  color: black;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  border-radius: 10px;
  border: 5px solid #081b38;
  cursor: pointer;
}
.div-direction {
  display: flex;
  justify-content: center;
}
.direction {
  text-decoration: underline;
  color: #455ed6;
  font-size: 20px;
  cursor: pointer;
}
.text-overline {
  font-weight: 800 !important;
}
.text-card {
  font-size: 19px;
  width: 50%;
}
.icon-color {
  padding-bottom: 25px;
  color: green;
}
.grind {
  background: rgba(141, 141, 141, 0.137);
  width: 97%;
  height: 300px;
  display: flex;
  flex-wrap: wrap;
}
.test {
  width: 25%;
  height: 50%;
  background: black;
}
@media (min-width: 600px) {
  .col-service {
    background: white;
  }
  .box {
    padding: 0px;
    margin-top: 0;
    box-shadow: none;
  }
  .header {
    justify-content: unset !important;
  }
}
@media (max-width: 600px) {
  .gallery {
    height: 150px !important;
  }
}
.gallery {
  width: 100%;
  height: 300px;
  background: black;
}
.col-service {
  background: white;
}
.box {
  padding: 0px;
  -webkit-box-shadow: 0px 2px 42px 9px #000000;
  box-shadow: 0px 2px 42px 9px #000000;
}
.header-btn {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: 10%;
  position: absolute;
  right: 0;
}

.header {
  height: 100px;
  display: flex;
  justify-content: space-between;
  background: #081b38;
  align-items: center;
}

.text-tittle {
  width: 100%;
  padding: 5px;
  font-size: 25px;
  text-align: center;
  color: #ffffff;
}
.btn-modal {
  height: 50px !important;
  background: #081b38;
  border-radius: unset;
}
.btn-modal-pencil {
  height: 50px !important;
  background: #081b38;
  border-radius: unset;
}
.divisor {
  height: auto !important;
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
}
.gallery-img {
  height: 145px;
}
.check {
  width: 40px !important;
  height: 40px !important;
  opacity: 0;
  z-index: 100;
}
.check:hover {
  opacity: 1;
}
.trash {
  width: 40px !important;
  height: 40px !important;
  opacity: 0;
  z-index: 100;
}
.trash:hover {
  opacity: 1;
}
.notes-profile {
  width: 25%;
  display: flex;
  justify-content: space-between;
}
.notes-text {
  width: 70%;
  padding-left: 15px;
  text-align: left;
}
.vue-lb-container {
  touch-action: pinch-zoom!important;
}
</style>
