<template>
  <v-hover v-slot="{ hover }">
    <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }">
      <v-card-text>
        <div class="images-col">
          <template v-if="show">
            <v-row>
              <v-container class="currentCondition">
                <h4>{{ name }}</h4>
                <img
                  v-bind:src="`http://openweathermap.org/img/w/${icon}.png`"
                  :alt="icon"
                />
                <div class="div-wheater">
                  <h3>{{ min }}° - {{ max }}°</h3>
                  <h3>{{ description }}</h3>
                </div>
              </v-container>
            </v-row>
            <v-row v-if="show" style="align-items: center;">
              <v-col
                v-for="(day, x) in forecast"
                :key="x"
                v-show="x != 0 && x < 6"
                class="dayweather"
              >
                <img
                  v-bind:src="
                    `http://openweathermap.org/img/w/${day.weather[0].icon}.png`
                  "
                  :alt="icon"
                />
                <p>{{ day.temp.min }}° - {{ day.temp.max }}°</p>
                <p>{{ day.weather[0].description }}</p>
                <p>{{ date(day.dt) }}</p>
              </v-col>
            </v-row>
          </template>
          <v-progress-circular
            v-else
            :size="90"
            :width="7"
            color="#081b38"
            indeterminate
            top="38%"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { mapState } from 'vuex';
export default {
  data: () => ({
    reload: false,
    icon: '01d',
    name: 'Los Angeles',
    min: 14.09,
    max: 22.6,
    description: 'sky is clear',
    forecast: [],
    show: false,
    dialog: false,
  }),
  mounted() {
    this.weather();
  },
  watch: {
    lat() {
      this.weather();
    },
  },
  computed: {
    ...mapState(['lat', 'lng']),
  },
  methods: {
    date(date) {
      return moment.unix(date).format('dddd');
    },
    weather() {
      const options = {
        method: 'GET',
        url: 'https://api.openweathermap.org/data/3.0/onecall',
        params: {
          lat: this.lat,
          lon: this.lng,
          units: 'imperial',
          appid: '22cb62f18bcf4e9db35355e47961e455',
        },
      };

      axios
        .request(options)
        .then((response) => {
          this.name = `${response.data.timezone} `;
          this.icon = response.data.current.weather[0].icon;

          this.min = response.data.daily[0].temp.min;
          this.max = response.data.daily[0].temp.max;
          this.forecast = response.data.daily;
          this.description = response.data.current.weather[0].description;
          this.show = true;
        })
        .catch(function(error) {
          console.error(error);
        });
    },
  },
};
</script>

<style>
.div-wheater {
  text-align: center;
}
.currentCondition {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.dayweather {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.div-wather-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img-weather-icon {
  max-height: 80px;
  max-width: 80px;
}
@media (max-width: 600px) {
  .img-weather-icon {
    max-height: 53px;
    max-width: 53px;
  }
}
</style>
