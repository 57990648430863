<template>
  <v-container class='services' justify='center'>
    <v-row>
      <v-col xs='10' md='8' align-self='center'>
        <v-btn-toggle v-model='toggle_multiple' class='scroll'>
          <v-btn depressed @click='get(1)'> Completed ({{ cont.completed }}) </v-btn>
          <v-btn depressed @click='get(0)'>
            Non Completed ({{ cont.nonCompleted }})
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col dense xs='2' md='auto' align-self='center'>
        <v-btn @click='get(), (toggle_multiple = [])'>
          <v-icon color='#081b38'>mdi-refresh</v-icon>
        </v-btn>
      </v-col>
      <v-col dense xs='6' md='auto' align-self='center'>
        <div class='text-center'>
          <v-skeleton-loader
            v-if='paginate'
            type='card-avatar, article, actions'
            height='30px'
            width='100%'
          ></v-skeleton-loader>
          <v-pagination
            v-else
            v-model='page'
            :length='pagination'
            color='#081b38'
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols='12' class='tittle-Table'>
        <h2>Actions Items {{ page }}</h2>
      </v-col>
      <v-col class='table-style'>
        <v-data-table
          :headers='headers'
          :items='data'
          :page.sync='page'
          :items-per-page='25'
          :loading='loader'
          hide-default-footer
          class='elevation-7'
          width='100%'
        >
          <template v-slot:item='{ item }'>
            <tr class='pointeTable' v-if='item.service' @click='goService(item.service_id)'>
              <td class='hidden-xs-only'>
                {{ parsedate(item.created_at) }}
              </td>
              <td class='hidden-xs-only'>
                {{ item.createBy.name }}
              </td>
              <td>
                <p v-html='item.description'></p>
              </td>
              <td>
                {{ item.service.name }}
              </td>
              <td class='hidden-xs-only'>
                {{ item.due_date }}
              </td>
              <td>
                {{ item.userAssigned.name }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apis from '@/apis';
import moment from 'moment';
import { FORMAT_DATE } from '@/constants';
import { mapState } from 'vuex';
export default {
  data: () => ({
    page: 1,
    id: null,
    cont: [],
    editService: false,
    id_status: null,
    data: [],
    paginate: false,
    filter_id: null,
    status_list: [],
    pagination: 1,
    actions: [],
    loader: false,
    itemsPerPage: 0,
    message: false,
    toggle_multiple: [],
    address: null,
    headers: [
      { text: 'On', align: 'start', value: 'id' },
      { text: 'Requested By', value: 'po' },
      { text: 'Actions Items', value: 'so' },
      { text: 'Project', value: 'name' },
      { text: 'Due Date', value: 'start' },
      { text: 'Assigned To', value: 'tech' },
    ],
  }),
  mounted() {
    this.get();
  },

  watch: {
    page() {
        if (this.toggle_multiple  === 1 || this.toggle_multiple === 0) {
            this.get(this.toggle_multiple);
        } else {
            this.get();
        }
    },
  },
  computed: {
    ...mapState(['notifications', 'token']),
  },
  methods: {
    parsedate(date) {
      return moment(date).format(FORMAT_DATE);
    },
    goService(id){
      this.$router.push(`/services/${id}`);
    },
    get(complete) {
      let url;
      if (complete === 1 || complete === 0) {
        url = `/todos?page=${this.page}&complete=${complete}`;
      } else {
        url = `/todos?page=${this.page}`;
      }
      this.loader = true;
      apis.getUrl(url).then((reponse) => {
            
          if (complete === undefined) {
            this.cont = reponse.data.completed[0]
          }
          this.loader = false;
          this.data = reponse.data.data.data;
          this.pagination = reponse.data.data.last_page;
        });
    },
  },
};
</script>

<style>
@media (min-width: 1264px) {
  .container {
    max-width: 96%;
  }
}
@media (min-width: 960px) {
  .container {
    max-width: 96%;
  }
}
.pointeTable {
  cursor: pointer;
}
.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 30px;
  min-height: 0;
  margin: 5px;
  min-width: 32px;
  border: azure;
  border-radius: 9px;
}
.v-btn-toggle > .v-btn.v-btn--active {
  color: #003790 !important;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 36px;
  padding: 0px 3px;
}
.tittle-Table {
  background-color: #081b38;
  color: white;
  margin: 0px;
  border-radius: 15px 15px 0px 0px;
}
.table-style {
  padding: 0px;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  color: #081b38;
  font-size: 15px;
}
.scroll {
  overflow-y: hidden;
  overflow-x: auto;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
  background: white;
}
</style>
