<template>
  <v-row>
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">User Profile</span>
              <v-btn
                icon
                color="#003790"
                @click="(dialog = false), clear()"
                class="user-btn-close"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" v-if="message" id="element">
                    <v-alert color="red" dense elevation="11" type="error">
                      <ul>
                        <li v-for="(error, i) in errors" :key="i">
                          {{ error }}
                        </li>
                      </ul>
                    </v-alert>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <div class="input-group">
                      <multiselect
                        v-model="type"
                        :disabled="disabled"
                        :options="optionsType"
                        placeholder="Choose your Type"
                        label="name"
                        track-by="name"
                      ></multiselect>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="name"
                      :disabled="disabled"
                      label="Name"
                      hint="example of helper text only on focus"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Email*"
                      required
                      v-model="email"
                      :disabled="disabled"
                      :rules="[rules.required, rules.email]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="employ"
                      :disabled="disabled"
                      label="Position/Title"
                      type="text"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      label="Phone"
                      type="number"
                      v-model="phone"
                      :disabled="disabled"
                      counter="10"
                      name="phone"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      label="Company"
                      type="text"
                      v-model="company"
                      :disabled="disabled"
                      name="company"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <VueGooglePlaces
                      :api-key="apikey"
                      @placechanged="onPlaceChanged"
                      :options="{ fields: ['geometry'] }"
                      country="usa"
                      placeholder="Enter an an address, zipcode, or location"
                    >
                      <v-text-field
                        label="Enter an an address, zipcode, or location"
                        v-model="address"
                        :disabled="disabled"
                        hide-details="auto"
                      ></v-text-field>
                    </VueGooglePlaces>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Note"
                      v-model="note"
                      :disabled="disabled"
                      name="note"
                      counter
                      max="500"
                      multi-line
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <div>
                <v-btn
                  color="#081b38"
                  text
                  @click="validate()"
                  :loading="btnloader"
                >
                  Save
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </v-row>
</template>
<script>
import apis from '@/apis';
import { mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
export default {
  data() {
    return {
      apikey: `${process.env.VUE_APP_API_KEY_MAPS}`,
      dialog: false,
      message: false,
      errors: [],
      type: [],
      disabled: false,
      btnloader: false,
      optionsType: [
        { name: 'Super Administrador', value: 'superadmin' },
        { name: 'Admin', value: 'admin' },
        { name: 'Technician', value: 'technician' },
        { name: 'Manager', value: 'manager' },
        { name: 'Customer', value: 'customer' },
        { name: 'Guest', value: 'guest' },
      ],
      colors: [
        'blue',
        'indigo',
        'deep-purple',
        'cyan',
        'green',
        'orange',
        'grey darken-1',
      ],
      name: null,
      email: null,
      employ: null,
      phone: null,
      option: null,
      company: null,
      address: null,
      note: null,
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        email: (value) => /.+@.+\..+/.test(value) || 'Invalid e-mail.',
      },
    };
  },
  computed: {
    ...mapState(['token', 'roles', 'techs', 'clients', 'managers']),
  },
  components: {
    Multiselect,
  },
  watch: {
    option() {
      switch (this.option) {
        case 'manager':
          this.type = { name: 'Manager', value: 'manager' };
          break;
        case 'client':
          this.type = { name: 'Customer', value: 'customer' };
          break;
        case 'tech':
          this.type = { name: 'Technician', value: 'technician' };
          break;

        default:
          this.type = [];
          break;
      }
    },
  },
  methods: {
    getValidation() {
      if (!this.name) {
        this.errors.push('El nombre de usuario es obligatorio.');
      }
      if (!this.email) {
        this.errors.push('El email de usuario es obligatorio.');
      }
      if (!this.employ) {
        this.errors.push('El employ de usuario es obligatorio.');
      }
      if (!this.phone) {
        this.errors.push('El phone de usuario es obligatorio.');
      }
      if (!this.company) {
        this.errors.push('El company de usuario es obligatorio.');
      }
      if (!this.address) {
        this.errors.push('El address de usuario es obligatorio.');
      }
      if (!this.type) {
        this.errors.push('El type de usuario es obligatorio.');
      }
    },
    validate() {
      this.errors = [];
      if (
        !this.name ||
        !this.employ ||
        !this.email ||
        !this.phone ||
        !this.address ||
        !this.company ||
        !this.type
      ) {
        this.message = true;
        this.getValidation();
      } else {
        this.message = false;
        this.create();
      }
    },
    onPlaceChanged(value) {
      this.address = value.formatted_address;
    },
    clear() {
      this.disabled = false;
      this.idUSer = null;
      this.name = null;
      this.employ = null;
      this.email = null;
      this.phone = null;
      this.address = null;
      this.company = null;
      this.type = null;
      this.note = null;
    },
    create() {
      this.btnloader = true;
      let post = {
        name: this.name,
        employ: this.employ,
        email: this.email,
        phone: this.phone,
        address: this.address,
        company: this.company,
        type: this.type.value,
        note: this.note,
        color: this.colors[this.rnd(0, this.colors.length - 1)],
      };

      apis
        .addUSer(post)
        .then((reponse) => {
          this.$swal.fire('User Created', '', 'success');
          this.services = reponse.data;
          this.btnloader = false;
          this.$parent.selectedUser(this.services);
          this.dialog = false;
          this.clear();
        })
        .catch((error) => {
          this.btnloader = false;
          this.$swal.fire('User Duplicated', '', error);
        });
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
};
</script>
