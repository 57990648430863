<template>
  <div>
    <template>
      <v-card class='flex-upl'>
        <v-card-text class='cartbody'>
          <div class='upload-mobile'>
            <vue-dropzone
              ref='myVueDropzone'
              :options='options'
              v-on:vdropzone-sending='sendingEvent'
              @vdropzone-queue-complete='complete'
              @vdropzone-success='success'
              v-on:vdropzone-thumbnail='thumbnail'
              id='customdropzone'
              :useCustomSlot='true'
              style='padding: 0;'
            >
              <div style='margin: 0!important; padding: 0!important;'>
                <v-icon color='black' style='font-size: 20px;'
                  >mdi-cloud-upload</v-icon
                >
                <p style='color: black'>
                  Drag & drop to upload file!
                </p>
                <div class='subtitle' style='color: black'>
                  ...or click to select a file from your computer
                </div>
              </div>
            </vue-dropzone>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
export default {
  data: () => ({
    id: null,
    dialog: false,
    length: null,
  }),
  props: ['idser'],
  computed: {
    ...mapState(['user', 'token', 'projectSelected']),
    options() {
      return {
        url: `${process.env.VUE_APP_BASE_URL_API}/api/v1/multimedias/uploadProject`,
        thumbnailWidth: 150,
        maxFilesize: 20,
        headers: {
          'My-Awesome-Header': 'header value',
          Authorization: `Bearer ${this.token}`,
          Accept: 'application/json',
        },
        previewTemplate: this.template(),
        dictDefaultMessage:
          "<p class='text-upl'>Drag & Drop <br> <small class='smalltext'>Your File to upload, or browse</small></p> <br> <h2 class='text-upl h2dropzone'>. . .</h2> <small class='smalltext'>upload your proyect images</small>",
      };
    },
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  methods: {
    success(file, response) {
      this.$emit('updateImage', response);
    },
    complete() {
      this.removeAllFiles();
    },
    removeAllFiles() {
      this.$refs.myVueDropzone.removeAllFiles();
    },
    sendingEvent(file, xhr, formData) {
      formData.append('id', this.projectSelected.id);
      formData.append('id_user', this.user.id);
    },

    template: function() {
      return `<div class='dz-preview dz-file-preview'>
                <div class='dz-image'>
                    <div data-dz-thumbnail-bg></div>
                </div>
                <div class='dz-details'>
                    <div class='dz-size'><span data-dz-size></span></div>
                    <div class='dz-filename'><span data-dz-name></span></div>
                </div>
                <div class='dz-progress'><span class='dz-upload' data-dz-uploadprogress></span></div>
                <div class='dz-error-message'><span data-dz-errormessage></span></div>
                <div class='dz-success-mark'><i class='fa fa-check'></i></div>
                <div class='dz-error-mark'><i class='fa fa-close'></i></div>
            </div>
        `;
    },
    thumbnail: function(file, dataUrl) {
      var j, len, ref, thumbnailElement;
      if (file.previewElement) {
        file.previewElement.classList.remove('dz-file-preview');
        ref = file.previewElement.querySelectorAll('[data-dz-thumbnail-bg]');
        for (j = 0, len = ref.length; j < len; j++) {
          thumbnailElement = ref[j];
          thumbnailElement.alt = file.name;
          thumbnailElement.style.backgroundImage = 'url(' + dataUrl + ')';
        }
        return setTimeout(
          // eslint-disable-next-line no-unused-vars
          (function(_this) {
            return function() {
              return file.previewElement.classList.add('dz-image-preview');
            };
          })(this),
          1,
        );
      }
    },
  },
};
</script>
