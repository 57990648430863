<template>
  <v-container>
    <v-tabs v-model="tab" centered icons-and-text>
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1">
        Taxes
        <v-icon>mdi-currency-usd</v-icon>
      </v-tab>

      <v-tab href="#tab-2">
        Terms
        <v-icon>mdi-file-document</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" style="padding-top: 45px;">
      <v-tab-item value="tab-1">
        <v-hover v-slot:default="{ hover }" open-delay="200">
          <v-card
            class="mb-3 card-center"
            width="80%"
            :elevation="hover ? 16 : 2"
          >
            <v-list-item>
              <v-list-item-avatar class="icon" size="80" color="red">
                <v-icon size="50" color="white">mdi-currency-usd</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="mb-3">
                  <strong>Change the taxes</strong></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-divider class="mx-6" color="red"></v-divider>
            <div class="card-dv">
              <v-form ref="form" v-model="valid" lazy-validation>
                <div class="div-input">
                  <label for="taxes">%</label>
                  <v-text-field
                    v-model="tax"
                    :rules="taxesRules"
                    label="Taxes %"
                    type="number"
                    name="taxes"
                    required
                  ></v-text-field>
                </div>

                <v-btn
                  color="success"
                  class="mr-4"
                  @click="validate"
                  :loading="loading"
                >
                  Change taxes
                </v-btn>
              </v-form>
            </div>
          </v-card>
        </v-hover>
      </v-tab-item>

      <v-tab-item value="tab-2">
        <v-hover v-slot:default="{ hover }" open-delay="200">
          <v-card
            class="mb-3 card-center"
            width="90%"
            :elevation="hover ? 16 : 2"
          >
            <v-list-item>
              <v-list-item-avatar class="icon" size="80" color="blue">
                <v-icon size="50" color="white">mdi-file-document</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="mb-3">
                  <strong>Terms</strong></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-divider class="mx-6" color="blue"></v-divider>
            <div class="card-dv">
              <v-data-table
                :headers="headers"
                :items="terms"
                class="elevation-1"
                style="width: 100%;"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Terms</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          New Term
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-form
                                ref="form2"
                                v-model="valid2"
                                lazy-validation
                              >
                                <v-col cols="12" sm="12" md="12">
                                  <v-text-field
                                    v-model="editedItem.name"
                                    :rules="[
                                      (v) => !!v || 'Tittle is required',
                                    ]"
                                    label="Term tittle"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                  <vue-editor
                                    v-model="editedItem.text"
                                    :rules="[
                                      (v) => !!v || 'Terms are required',
                                    ]"
                                    id="editor"
                                    name="description"
                                    ref="editor"
                                  ></vue-editor>
                                </v-col>
                              </v-form>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="close">
                            Cancel
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="save"
                            :loading="change"
                          >
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title class="text-h5"
                          >Are you sure you want to delete this
                          item?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItemConfirm"
                            :loading="change"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="(dialogView = !dialogView), setDialog(item)"
                  >
                    mdi-eye
                  </v-icon>
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </template>
                <template v-slot:no-data> </template>
              </v-data-table>
            </div>
          </v-card>
        </v-hover>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="dialogView" max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          {{ itemSelected.name }}
        </v-card-title>

        <v-spacer></v-spacer>
        <v-card-text>
          <p v-html="itemSelected.text"></p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue"
            text
            @click="(dialogView = false), (itemSelected = [])"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import apis from '@/apis';
import { mapState, mapMutations } from 'vuex';
import { VueEditor } from 'vue2-editor';
export default {
  data() {
    return {
      change: false,
      checkbox: false,
      valid: true,
      valid2: true,
      tax: null,
      dialogView: false,
      loading: false,
      taxesRules: [(v) => !!v || 'taxes is required'],
      tab: null,
      text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Tittle',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Actions', value: 'actions', sortable: false, align: 'end' },
      ],
      editedIndex: -1,
      editedItem: {
        id: null,
        name: '',
        text: null,
      },
      defaultItem: {
        id: null,
        name: '',
        text: null,
      },
      itemSelected: [],
    };
  },
  components: {
    VueEditor,
  },
  mounted() {
    this.tax = this.taxes.value;
  },
  computed: {
    ...mapState(['token', 'taxes', 'terms']),
    formTitle() {
      return this.editedIndex === -1 ? 'New Term' : 'Edit Term';
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    ...mapMutations(['setTaxes', 'setTerm', 'editTerm', 'deleteTerm']),
    setDialog(item) {
      this.itemSelected = item;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let post = {
          taxes: this.tax,
        };
        apis.sendPost('/taxes', post).then((reponse) => {
          this.setTaxes(reponse.data.data);
          this.$swal.fire(reponse.data.message, '', 'success');
          this.checkbox = false;
          this.loading = false;
        });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    editItem(item) {
      this.editedIndex = this.terms.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.terms.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.change = true;
      apis.getUrl(`/deleteTerms/${this.editedItem.id}`).then(() => {
        this.deleteTerm(this.editedIndex);
        this.change = false;
        this.closeDelete();
        this.$swal.fire('Term Deleted!', '', 'success');
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.change = true;
      let post = {
        id: this.editedItem.id,
        name: this.editedItem.name,
        text: this.editedItem.text,
      };
      if (this.$refs.form2.validate() && this.editedItem.text !== null) {
        apis.sendPost('/term', post).then((reponse) => {
          var parse = JSON.parse(reponse.data.data.value);
          var name = parse.name;
          var text = parse.text;
          let newTerm = {
            id: reponse.data.data.id,
            name: name,
            text: text,
          };
          if (this.editedIndex > -1) {
            this.editTerm(newTerm);

            this.$swal.fire('Term Updated!', '', 'success');
          } else {
            this.$swal.fire('Term Added!', '', 'success');
            this.setTerm(newTerm);
          }

          this.change = false;
          this.close();
        });
      } else {
        this.editedItem.text =
          "<p><u style='color: rgb(230, 0, 0);'>Terms are required</u></p>";
      }
    },
  },
};
</script>
<style scoped>
.v-tab--active {
  color: white !important;
  background: #081b38;
  border-radius: 8px;
}
.v-tabs-slider {
  background-color: unset;
  height: 100%;
  width: 100%;
}
.card-center {
  margin: auto;
}
.card-dv {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  padding-bottom: 25px;
}
.div-input {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
