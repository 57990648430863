<template>
  <div class='about'>
    <h1>Reditrect to https://mail.google.com .......</h1>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    mounted() {
        setTimeout(() => (this.go()), 1000);
    },
    methods: {
        go(){
        window.open(
            `https://mail.google.com/mail/u/0/#inbox`,
            '_blank'
        );
        this.$router.push('/');
        }
    },
    computed: {
      ...mapState(['token','user']),
    },
}
</script>