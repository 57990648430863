<template>
  <v-row justify="center">
    <v-card-text>
      <v-container padding-top="15px">
        <v-row style="justify-content: center;">
          <v-expand-transition>
            <v-col cols="12" v-if="message" id="element">
              <v-alert color="red" dense elevation="11" type="error">
                <ul>
                  <li v-for="(error, i) in errors" :key="i">{{ error }}</li>
                </ul>
              </v-alert>
            </v-col>
          </v-expand-transition>

          <v-text-field
            v-model="name"
            label="Project/Service Name"
            prepend-icon="mdi-clipboard-text"
            color="#003790"
            name="name"
          ></v-text-field>

          <v-container fluid>
            <v-row>
              <v-col cols="5">
                <v-text-field
                  v-model="po"
                  label="PO# (required)"
                  color="#003790"
                  name="po"
                ></v-text-field>
              </v-col>
              <v-col cols="2"></v-col>
              <v-col cols="5">
                <v-text-field
                  v-model="so"
                  label="SO#"
                  color="#003790"
                  name="so"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="col-account ajust">
                <div>
                  <v-icon class="v-icon icon icono ajust-icon"
                    >mdi-google-maps</v-icon
                  >
                </div>
                <div class="label-multiselect" style="width: 100%;">
                  <VueGooglePlaces
                    :api-key="apikey"
                    @placechanged="onPlaceChanged"
                    placeholder="Enter an an address, zipcode, or location"
                  >
                    <v-text-field
                      v-model="address"
                      hide-details="auto"
                    ></v-text-field>
                  </VueGooglePlaces>
                </div>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="manager"
                  :items="managers"
                  item-text="name"
                  :item-value="'id'"
                  menu-props="auto"
                  label="Assign PM/SC/PE"
                  hide-details
                  color="#003790"
                  prepend-icon="mdi-account"
                  single-line
                ></v-select>
                <v-btn
                  color="#003790"
                  class="addBTN"
                  text
                  small
                  @click="openModal('manager')"
                  >New PM/SC/PE</v-btn
                >
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="client"
                  :items="clients"
                  item-text="name"
                  :item-value="'id'"
                  menu-props="auto"
                  label="Assign Client"
                  hide-details
                  color="#003790"
                  prepend-icon="mdi-account"
                  single-line
                ></v-select>
                <v-btn
                  color="#003790"
                  class="addBTN"
                  text
                  small
                  @click="openModal('client')"
                  >New client</v-btn
                >
              </v-col>
              <v-col cols="12" class="col-account ajust">
                <div>
                  <v-icon class="v-icon icon icono ajust-icon"
                    >mdi-account-supervisor</v-icon
                  >
                </div>
                <div class="label-multiselect" style="width: 100%;">
                  <label class="typo__label">Add tech(s)</label>
                  <multiselect
                    v-model="tech"
                    :options="techs"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Choose tech(s)"
                    label="name"
                    track-by="name"
                    :preselect-first="false"
                  >
                    <template slot="selection" slot-scope="{ values, isOpen }"
                      ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >{{ values.length }} Techs selected</span
                      ></template
                    >
                  </multiselect>
                  <v-btn
                    color="#003790"
                    class="addBtn-multiselect"
                    text
                    small
                    @click="openModal('tech')"
                    >New tech</v-btn
                  >
                </div>
              </v-col>
              <v-col cols="12">
                <v-menu
                  ref="dialog"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fixedates"
                      label="Date start-end"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dates" range>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu2 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(dates)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-row>
                <v-col cols="1">
                  <v-icon class="v-icon icon">mdi-format-align-justify</v-icon>
                </v-col>
                <v-col cols="11" height="100">
                  <vue-editor
                    v-model="content"
                    id="editor"
                    name="description"
                  ></vue-editor>
                </v-col>
              </v-row>
            </v-row>
          </v-container>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="openModalDelete()">
          Delete
        </v-btn>
        <v-btn color="red" text @click="close()">
          Close
        </v-btn>
        <v-btn color="green" text @click="validate()" :loading="btnloader">
          Save
        </v-btn>
      </v-card-actions>
    </v-card-text>
    <UserModal ref="usermodaledit" />
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import apis from '@/apis';
import { VueEditor } from 'vue2-editor';
import Multiselect from 'vue-multiselect';
import UserModal from '@/components/UserModal.vue';
export default {
  data: () => ({
    loading: false,
    btnloader: false,
    dialog2: false,
    files: [],
    message: false,
    fixedates: null,
    errors: [],
    dialog: false,
    name: null,
    po: null,
    so: null,
    address: null,
    manager: null,
    client: null,
    tech: [],
    statusSet: null,
    apikey: process.env.VUE_APP_API_KEY_MAPS,
    dates: [],
    menu2: false,
    body: null,
    content: '<h3>Add description</h3>',
    customToolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  }),
  mounted() {
    this.getService();
  },
  props: {
    data: {
      type: Object,
      require: true,
    },
  },
  computed: {
    ...mapState(['user', 'techs', 'clients', 'managers', 'status', 'token']),
    service: function() {
      return this.data;
    },
  },
  methods: {
    ...mapMutations(['setModalProyect', 'deleteService']),
    openModalDelete() {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: 'You wont be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            apis.deleteService(this.service.id).then(() => {
              this.$swal.fire(
                'Deleted!',
                'Your Service has been deleted.',
                'success',
              );
              this.dialog2 = false;
              this.loading = false;
              this.close();
            });
          }
        });
      this.loading = true;
    },
    selectedUser(user) {
      switch (user.type) {
        case 'customer':
          this.client = user.id;
          break;
        case 'technician':
          this.tech.push(user);
          break;
        case 'manager':
          this.manager = user.id;
          break;
        default:
          break;
      }
    },
    openModal(option) {
      this.$refs.usermodaledit.option = option;
      this.$refs.usermodaledit.dialog = true;
    },
    onPlaceChanged(value) {
      this.address = value.formatted_address;
    },
    close() {
      this.$parent.edit = true;
    },
    getService() {
      this.name = this.service.name;
      this.manager = this.service.manager.id;
      this.client = this.service.customer.id;
      this.po = this.service.po_purchase;
      this.so = this.service.so;
      this.address = this.service.address;
      this.dates[0] = this.service.start;
      this.dates[1] = this.service.end;
      this.content = this.service.description;
      this.statusSet = this.service.status.id;
      this.tech = [...this.service.users];
      this.fixedates = this.dates.join(' - ');
    },
    getValidation() {
      if (!this.name) {
        this.errors.push('The project / service name is required. ');
      }
      if (!this.manager) {
        this.errors.push('The manager is required.');
      }
      if (!this.client) {
        this.errors.push('The client is required.');
      }
      if (!this.po) {
        this.errors.push('The po is required.');
      }
      if (!this.address) {
        this.errors.push('The address is required.');
      }
      if (this.dates.length != 2) {
        this.errors.push('Dates are required .');
      }
      if (!this.tech.length > 0) {
        this.errors.push('The tech is required.');
      }
    },
    validate() {
      this.errors = [];
      if (
        !this.name ||
        !this.manager ||
        !this.client ||
        !this.po ||
        !this.address ||
        this.dates.length != 2 ||
        !this.tech.length > 0
      ) {
        this.message = true;
        this.getValidation();
      } else {
        this.message = false;
        this.editProject();
      }
    },
    editProject() {
      this.btnloader = true;
      let post = {
        name: this.name,
        manager: this.manager,
        customer: this.client,
        po_purchase: this.po,
        so: this.so,
        address: this.address,
        start: this.dates[0],
        end: this.dates[1],
        description: this.content,
        created_by: this.user.id,
        status_id: this.statusSet,
        techs: this.tech,
      };
      apis
        .updateService(this.service.id, post)
        .then((data) => {
          if (data.data.success) {
            this.$swal.fire(
              'Updated!',
              'Your Service has been updated.',
              'success',
            );
            this.$emit('update');
          } else {
            this.$swal.fire('error', data.data.message, 'error');
          }
          this.btnloader = false;
        })
        .catch((error) => {
          this.btnloader = false;
          this.$swal.fire('error', JSON.stringify(error), 'error');
        });
    },
  },
  components: {
    VueEditor,
    Multiselect,
    UserModal,
  },
};
</script>

<style>
.ajust-icon {
  margin: 0 !important;
  padding: 0 9px 0px 0px !important;
}
.ajust {
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 90%;
  background: white;
}
.add-proyect {
  bottom: 12% !important;
  right: 4% !important;
  position: fixed;
}
.headline {
  padding-top: 20px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
.closeBTN {
  position: absolute;
  top: 10px;
  right: 15px;
}
.addBTN {
  margin-left: 32px;
}
.v-text-field {
  padding-top: 10px;
  margin-top: 0px;
}
.icon {
  margin-left: 14px;
  margin-top: 2px !important;
}
</style>
