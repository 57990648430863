<template>
  <v-row justify="space-around">
    <v-col cols="auto btn">
      <v-btn
        icon
        outlined
        elevation="10"
        x-large
        color="primary"
        @click="dialog = true"
        >+</v-btn
      >
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <v-card>
          <v-card-text>
            <div class="">
              <v-row>
                <v-expand-transition>
                  <v-col cols="12" v-if="message" id="element">
                    <v-alert color="red" dense elevation="11" type="error">
                      <ul>
                        <li v-for="(error, i) in errors" :key="i">
                          {{ error }}
                        </li>
                      </ul>
                    </v-alert>
                  </v-col>
                </v-expand-transition>

                <v-container fluid>
                  <v-row>
                    <v-col cols="5">
                      <v-text-field
                        v-model="po"
                        label="PO# (required)"
                        color="#003790"
                        name="po"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2"></v-col>
                    <v-col cols="5">
                      <v-text-field
                        v-model="so"
                        label="SO#"
                        color="#003790"
                        name="so"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="manager"
                        :items="managers"
                        :item-value="'id'"
                        item-text="name"
                        menu-props="auto"
                        label="Assign PM/SC/PE"
                        hide-details
                        color="#003790"
                        prepend-icon="mdi-account"
                        single-line
                      ></v-select>
                      <v-btn
                        color="#003790"
                        class="addBTN"
                        text
                        small
                        @click="openModal('manager')"
                        >New PM/SC/PE</v-btn
                      >
                    </v-col>
                    <v-col cols="12" class="col-account">
                      <div>
                        <v-icon class="v-icon icon icono"
                          >mdi-account-supervisor</v-icon
                        >
                      </div>
                      <div class="label-multiselect">
                        <label v-if="statusSet !== 15" class="typo__label"
                          >Add tech(s)</label
                        >
                        <label v-else class="typo__label"
                          >Add more managers(s)</label
                        >
                        <multiselect
                          v-if="statusSet !== 15"
                          v-model="tech"
                          :options="techs"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          :preserve-search="true"
                          placeholder="Choose tech(s)"
                          label="name"
                          track-by="name"
                          :preselect-first="false"
                        >
                          <template
                            slot="selection"
                            slot-scope="{ values, isOpen }"
                            ><span
                              class="multiselect__single"
                              v-if="values.length &amp;&amp; !isOpen"
                              >{{ values.length }} Techs selected</span
                            ></template
                          >
                        </multiselect>
                        <multiselect
                          v-else
                          v-model="tech"
                          :options="managers"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          :preserve-search="true"
                          placeholder="Choose manager(s)"
                          label="name"
                          track-by="name"
                          :preselect-first="false"
                        >
                          <template
                            slot="selection"
                            slot-scope="{ values, isOpen }"
                            ><span
                              class="multiselect__single"
                              v-if="values.length &amp;&amp; !isOpen"
                              >{{ values.length }} Techs selected</span
                            ></template
                          >
                        </multiselect>
                        <v-btn
                          v-if="statusSet !== 15"
                          color="#003790"
                          class="addBtn-multiselect"
                          text
                          small
                          @click="openModal('tech')"
                          >New tech</v-btn
                        >
                        <v-btn
                          v-else
                          color="#003790"
                          class="addBtn-multiselect"
                          text
                          small
                          @click="openModal('manager')"
                          >New Manager</v-btn
                        >
                      </div>
                    </v-col>
                    <v-col cols="12" v-if="statusSet !== 15">
                      <v-menu
                        ref="dialog"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dateRangeText"
                            label="Date start-end"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="dates" range>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu2 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(dates)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-row>
                      <v-col cols="1">
                        <v-icon class="v-icon icon"
                          >mdi-format-align-justify</v-icon
                        >
                      </v-col>
                      <v-col cols="11">
                        <vue-editor
                          v-model="content"
                          id="editor"
                          name="description"
                        ></vue-editor>
                      </v-col>
                    </v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="statusSet"
                        :items="status_list"
                        item-text="name"
                        :item-value="'id'"
                        label="Status"
                        color="#003790"
                        prepend-icon="mdi-list-status"
                        persistent-hint
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" v-if="statusSet == 15">
                      <v-text-field
                        prepend-icon="mdi-google-drive"
                        v-model="drive"
                        label="Link Google Drive"
                        color="#003790"
                        name="drive"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn color="red" text @click="(dialog = false), reset()">
              Close
            </v-btn>
            <v-btn
              color="green"
              text
              :loading="btnloader"
              @click="checkvalidate()"
              href="#element"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <UserModal ref="usermodal" />
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import apis from '@/apis';
import { VueEditor } from 'vue2-editor';
import Multiselect from 'vue-multiselect';
import UserModal from '@/components/UserModal.vue';

export default {
  data: () => ({
    apikey: `${process.env.VUE_APP_API_KEY_MAPS}`,
    btnloader: false,
    search: null,
    message: false,
    errors: [],
    drive: '',
    po: null,
    so: null,
    manager: [],
    tech: [],
    statusSet: null,
    status_list: [],
    dialog: false,
    dates: [],
    menu2: false,
    body: null,
    content: '<h3>Add description</h3>',
    customToolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  }),
  mounted() {
    this.status_list = this.status_project;
  },
  methods: {
    ...mapMutations(['addDates', 'addProject']),
    selectedUser(user) {
      switch (user.type) {
        case 'customer':
          this.client = user.id;
          break;
        case 'technician':
          this.tech.push(user);
          break;
        case 'manager':
          this.manager = user.id;
          break;
        default:
          break;
      }
    },
    openModal(option) {
      this.$refs.usermodal.option = option;
      this.$refs.usermodal.dialog = true;
    },
    checkvalidate() {
      if (this.statusSet !== 15) {
        this.check();
      } else {
        this.checkP();
      }
    },
    reset() {
      this.manager = null;
      this.po = null;
      this.so = null;
      this.dates = [];
      this.content = null;
      this.tech = [];
      this.drive = '';
      this.message = false;
      this.errors = [];
    },
    check() {
      this.errors = [];
      if (
        !this.manager ||
        !this.po ||
        this.dates.length < 2 ||
        !this.statusSet
      ) {
        if (!this.manager) {
          this.errors.push('The manager is required.');
          this.message = true;
        }
        if (this.dates.length < 2) {
          this.errors.push('Dates start - end are required.');
          this.message = true;
        }
        if (this.statusSet !== 15) {
          if (!this.tech.length > 0) {
            this.errors.push('The tech is required.');
            this.message = true;
          }
        }

        if (!this.statusSet) {
          this.errors.push('The status is required.');
          this.message = true;
        }
      } else {
        this.message = false;
        this.addProjectPost();
      }
    },
    checkP() {
      this.errors = [];
      if (!this.manager || !this.po || !this.statusSet) {
        if (!this.manager) {
          this.errors.push('The manager is required.');
          this.message = true;
        }
        if (this.statusSet !== 15) {
          if (!this.tech.length > 0) {
            this.errors.push('The tech is required.');
            this.message = true;
          }
        }

        if (!this.statusSet) {
          this.errors.push('The status is required.');
          this.message = true;
        }
      } else {
        this.message = false;
        this.addProjectPost();
      }
    },
    addProjectPost() {
      this.btnloader = true;
      let post = {
        project_id: this.projectSelected.id,
        name: this.projectSelected.name,
        manager: this.manager,
        customer: this.projectSelected.customer.name,
        po_purchase: this.po,
        so: this.so,
        address: this.projectSelected.address,
        start: this.dates[0],
        end: this.dates[1],
        description: this.content,
        drive: this.drive,
        created_by: this.user.id,
        status_id: this.statusSet,
        techs: this.tech,
      };
      apis
        .addTicket(post)
        .then(() => {
          let date = {
            name: this.projectSelected.name,
            start: this.dates[0],
            end: this.dates[1],
          };
          if (this.statusSet != 15) {
            this.addDates(date);
          }
          this.$parent.message = true;
          this.btnloader = false;
          this.dialog = false;
          this.reset();
        })
        .catch((error) => {
          this.btnloader = false;
          this.$swal.fire(
            'Error',
            error.response.data.message,
            'error',
          );
        });
    },
  },
  computed: {
    ...mapState([
      'user',
      'projectSelected',
      'techs',
      'clients',
      'managers',
      'token',
      'status_project',
      'projects',
    ]),
    dateRangeText() {
      return this.dates.join(' - ');
    },
  },
  components: {
    VueEditor,
    Multiselect,
    UserModal,
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.btn {
  position: fixed;
  right: 0;
  top: 90vh;
}

.group-btn {
  justify-content: flex-end;
}
.form-control {
  border-bottom: 1px solid #616161;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.col-account {
  display: flex;
  align-items: center;
  align-content: center;
}
.add-proyect {
  bottom: 12% !important;
  right: 4% !important;
  position: fixed;
}
.headline {
  padding-top: 20px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
.closeBTN {
  position: absolute;
  top: 10px;
  right: 15px;
}
.addBTN {
  margin-left: 32px;
}
.v-text-field {
  padding-top: 10px;
  margin-top: 0px;
}
.icon {
  margin-left: 14px;
  margin-top: 2px !important;
}
.icono {
  margin: 0 !important;
  padding-right: 3px !important;
}
.label-multiselect {
  width: 100%;
}
.addBtn-multiselect {
  margin: 0px !important;
}
.v-btn--fab.v-size--default {
  height: 75px !important;
  width: 75px !important;
}
</style>
