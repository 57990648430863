<template>
  <div style='width: 90%;'>
    <v-sheet class='calendar-btn' v-if='permision'>
      <v-btn
      color='red'
      style='color: white;'
      @click='getcalendar()'
      >
        <v-icon>mdi-calendar-lock</v-icon>
         Request Permissiong
      </v-btn>
    </v-sheet>
    <div v-else>
      <v-sheet
        tile
        height='54'
        class='d-flex'
      >
        <v-btn
          icon
          class='ma-2'
          @click='prev'
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          outlined
          class='mr-4'
          color='grey darken-2'
          @click='setToday'
        >
          Today
        </v-btn>
        <v-select
          v-model='type'
          :items='types'
          dense
          outlined
          hide-details
          class='ma-2'
          label='type'
        ></v-select>
        <v-spacer class='date'>
          <v-toolbar-title v-if='$refs.calendario' style='padding: 5px;'>
            {{ $refs.calendario.title }}
          </v-toolbar-title> 
          <v-btn
          color='green'
          style='color: white;'
          @click='addevents()'
          >
            <v-icon>mdi-refresh</v-icon>
            Refresh Events
          </v-btn>
        </v-spacer>
        <v-btn
          icon
          class='ma-2'
          @click='next'
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-sheet>
      <v-sheet height='600' width='100%'>
        <v-calendar
          width='100%'
          ref='calendario'
          v-model='value'
          color='primary'
          :type='type'
          @click:event='showEvent'
          :events='eventsCalendar'
          :event-color='getEventColor'
        ></v-calendar>
        <v-menu
            v-model='selectedOpen'
            :close-on-content-click='false'
            :activator='selectedElement'
            offset-x
            width='50%'
          >
            <v-card
              color='grey lighten-4'
              min-width='350px'
              flat
            >
            
              <v-btn @click='selectedOpen = false' icon dark class='closeC'>
                <v-icon color='black'>mdi-close</v-icon>
              </v-btn>
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols='1'>
                    <v-icon :color='selectedEvent.color'>mdi-alpha-n-box</v-icon>
                  </v-col>
                  <v-col cols='11'>
                    <h3>{{selectedEvent.name}}</h3>
                    <p>{{ getDates(selectedEvent.start)}} - {{ getDates(selectedEvent.end)}}</p>
                  </v-col>
                </v-row>
                <v-row no-gutters v-if='api'>
                  <v-col cols='1'>
                    <v-icon :color='selectedEvent.color'>mdi-map-marker</v-icon>
                  </v-col>
                  <v-col cols='11'>
                    <h3>{{selectedEventFull.location}}</h3>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols='1'>
                    <v-icon :color='selectedEvent.color'>mdi-text</v-icon>
                  </v-col>
                  <v-col cols='11'>
                    <span v-html='selectedEvent.description'></span>
                  </v-col>
                </v-row>
                <v-row no-gutters v-if='api'>
                  <v-col cols='1'>
                    <v-icon :color='selectedEvent.color'>mdi-google-drive</v-icon>
                  </v-col>
                  <v-col cols='11'>
                    <v-chip
                      v-for='(attachment, i) in selectedEventFull.attachments'
                      :key='i'
                      class='ma-2'
                      label
                      @click='goAttachment(attachment.fileUrl)'
                    >
                      <img :src='attachment.iconLink' alt="icon">
                      {{ attachment.title }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-menu>
      </v-sheet>

    </div>
  </div>
</template>
<script>
import moment from 'moment'
import apis from '@/apis';
import { mapState, mapMutations } from 'vuex';
  export default {
    data: () => ({
      type: 'month',
      types: ['month', 'week', 'day', '4day'],
      value: '',
      events: [],
      google_id: null,
      selectedEvent: {},
      selectedElement: null,
      permision: true,
      api: false,
      selectedEventFull: {},
      selectedOpen: false,
      eventsCalendar: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    }),
    mounted () {
      
      if (this.$route.query.auth) {
        this.permision = false
        setTimeout(() => {
          this.addevents()
          this.setCalendar(true)
        }, 1000);
      } else if (this.user.google_acount || this.calendar) {
        this.permision = false
        setTimeout(() => {
          this.addevents()
        }, 1000)
      }
    },
    computed: {
      ...mapState(['token' , 'user', 'calendar']),
    },
    methods: {
    ...mapMutations(['setCalendar']),
    getDates(date){
      return moment(date).format('MM-DD-YYYY hh:mm')
    },
      prev () {
        this.$refs.calendario.prev()
      },
      next () {
        this.$refs.calendario.next()
      },
      goAttachment(url){
        window.open(
          `${url}`
        );
      },
      showEvent ({ nativeEvent, event }) {
        this.api= false
        const open = () => {
          this.selectedEventFull={}
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => {
            this.selectedOpen = true
          }, 10)
          apis.getUrl(`/events/${event.id}`).then((reponse) => {
              this.api= reponse.data.status
              if (this.api) {
                this.selectedEventFull= reponse.data.event.googleEvent
              }
          });
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      setToday () {
        this.value = ''
      },
      getEventColor (event) {
        return event.color
      },
      addevents (){
        apis.getUrl('/events').then((reponse) => {
         this.$refs.calendario.checkChange()
          this.events = reponse.data;
          
          this.eventsCalendar = this.events.map((event) => {
            if (event.allday) {
              return {
                id: event.google_id,
                name: event.name,
                description: event.description,
                start: moment(event.started_at).add(1, 'days').format(
                  'YYYY-MM-DD hh:mm'
                ),
                end: moment(event.ended_at).format(
                  'YYYY-MM-DD hh:mm'
                ),
                color: this.colors[this.rnd(0, this.colors.length - 1)],
                timed: event.allday
              }
            } else {
              return {
                id: event.google_id,
                name: event.name,
                description: event.description,
                start: moment(event.started_at).format(
                  'YYYY-MM-DD hh:mm'
                ),
                end: moment(event.ended_at).format(
                  'YYYY-MM-DD hh:mm'
                ),
                color: this.colors[this.rnd(0, this.colors.length - 1)],
                timed: event.allday
              }
            }
          })
            this.prev();
            this.next();
        });
 

      },
      getcalendar () {
        window.open(
          `${process.env.VUE_APP_BASE_URL_API}/google/oauth`,'_self'
        );
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
    },
  }
</script>
<style>
.date{
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendar-btn{
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.closeC{
    position: fixed;
    z-index: 100;
    right: 0px;
}
</style>