<template>
  <v-container>
    <v-row>
      <v-col xs="10" md="8" align-self="center">
        <v-btn-toggle v-model="toggle_multiple" class="scroll">
          <v-btn
            depressed
            v-for="(statu, i) in roles"
            :key="i"
            @click="
              getUserbyRol(statu.type), (searchRol = true), (type = statu.type)
            "
          >
            {{ statu.name }} ({{ statu.cont }})
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col dense xs="2" md="auto" align-self="center">
        <v-btn
          @click="
            getUsers(),
              (toggle_multiple = []),
              (searchRol = false),
              (type = null)
          "
        >
          <v-icon color="#081b38">mdi-refresh</v-icon>
        </v-btn>
      </v-col>
      <v-col dense xs="6" md="auto" align-self="center">
        <div class="text-center">
          <v-skeleton-loader
            v-if="paginate"
            type="card-avatar, article, actions"
            height="30px"
            width="100%"
          ></v-skeleton-loader>
          <v-pagination
            v-else
            v-model="page"
            :length="pagination"
            color="#081b38"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="tittle-Table">
        <h2>Current Users</h2>
      </v-col>
      <v-col class="table-style">
        <v-card>
          <v-skeleton-loader
            v-if="loader"
            type="table-heading, list-item-two-line, image, table-tfoot"
          ></v-skeleton-loader>
          <v-data-table
            v-else
            :headers="headers"
            :items="users.data"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-7"
            width="100%"
          >
            <template v-slot:item="{ item }">
              <tr class="pointeTable">
                <td @click="(dialog = true), edirUser(item), (edit = true)">
                  {{ item.id }}
                </td>
                <td @click="(dialog = true), edirUser(item), (edit = true)">
                  <v-avatar size="26px">
                    <img :src="item.avatar" :alt="item.name"
                  /></v-avatar>
                </td>
                <td @click="(dialog = true), edirUser(item), (edit = true)">
                  {{ item.name }}
                </td>
                <td @click="(dialog = true), edirUser(item), (edit = true)">
                  {{ item.email }}
                </td>
                <td>
                  <a href="tel:+" class="tel"
                    ><v-icon class="ikon">mdi-phone</v-icon>{{ item.phone }}</a
                  >
                </td>
                <td @click="(dialog = true), edirUser(item), (edit = true)">
                  {{ item.type }}
                </td>
                <td>
                  <v-switch
                    v-model="item.status"
                    value
                    color="success"
                    :input-value="item.status"
                    @click="changStatus(!item.status, item.id)"
                  ></v-switch>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="pagination">
      <v-col dense xs="6" md="auto" align-self="center">
        <div class="text-center">
          <v-skeleton-loader
            v-if="paginate"
            type="card-avatar, article, actions"
            height="30px"
            width="100%"
          ></v-skeleton-loader>
          <v-pagination
            v-else
            v-model="page"
            :length="pagination"
            color="#081b38"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <template>
        <v-row justify="center">
          <v-dialog v-model="dialog" persistent max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="add-proyect"
                color="white"
                dark
                absolute
                bottom
                right
                fab
                v-bind="attrs"
                v-on="on"
                @click="clear()"
                elevation="17"
              >
                <v-icon color="black">mdi-plus</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">User Profile</span>
                <v-btn
                  icon
                  color="#003790"
                  @click="(dialog = false), clear()"
                  class="user-btn-close"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" v-if="message" id="element">
                      <v-alert color="red" dense elevation="11" type="error">
                        <ul>
                          <li v-for="(error, i) in errors" :key="i">
                            {{ error }}
                          </li>
                        </ul>
                      </v-alert>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <div class="input-group">
                        <multiselect
                          v-model="type"
                          :disabled="disabled"
                          :options="optionsType"
                          placeholder="Choose your Type"
                          label="name"
                          track-by="name"
                        ></multiselect>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="name"
                        :disabled="disabled"
                        label="Name"
                        hint="example of helper text only on focus"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <div class="div-email">
                        <v-text-field
                          label="Email*"
                          required
                          v-model="email"
                          :disabled="disabled"
                          :rules="[rules.required, rules.email]"
                        ></v-text-field>
                        <label style="margin: 5px;">Gmail</label>
                        <v-switch
                          v-model="notGmail"
                          value
                          color="success"
                          input-value="item.status"
                        ></v-switch>
                      </div>
                      <v-text-field
                        v-show="!notGmail"
                        id="password"
                        label="Password"
                        name="password"
                        :required="!notGmail"
                        :disabled="disabled"
                        v-model="password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'"
                        counter
                        @click:append="show1 = !show1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="employ"
                        :disabled="disabled"
                        label="Position/Title"
                        type="text"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        label="Phone"
                        type="number"
                        v-model="phone"
                        :disabled="disabled"
                        counter="10"
                        name="phone"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        label="Company"
                        type="text"
                        v-model="company"
                        :disabled="disabled"
                        name="company"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <VueGooglePlaces
                        :api-key="apikey"
                        @placechanged="onPlaceChanged"
                        :options="{ fields: ['geometry'] }"
                        country="usa"
                        placeholder="Enter an an address, zipcode, or location"
                      >
                        <v-text-field
                          label="Enter an an address, zipcode, or location"
                          v-model="address"
                          :disabled="disabled"
                          hide-details="auto"
                        ></v-text-field>
                      </VueGooglePlaces>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Note"
                        v-model="note"
                        :disabled="disabled"
                        name="note"
                        counter
                        max="500"
                        multi-line
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <div v-if="!edit">
                  <v-btn
                    color="#081b38"
                    text
                    @click="validate()"
                    :loading="btnloader"
                  >
                    Save
                  </v-btn>
                </div>
                <div v-else>
                  <v-btn
                    v-if="disabled"
                    color="#081b38"
                    text
                    @click="disabled = false"
                    :loading="btnloader"
                  >
                    Edit
                  </v-btn>
                  <v-btn
                    v-else
                    color="#081b38"
                    text
                    @click="updateuser()"
                    :loading="btnloader"
                  >
                    Update
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </v-row>
  </v-container>
</template>
<script>
import apis from '@/apis';
import { mapState, mapMutations } from 'vuex';
import Multiselect from 'vue-multiselect';
export default {
  data: () => ({
    notGmail: false,
    status_list: [
      { name: 'superadmin' },
      { name: 'admin' },
      { name: 'manager' },
      { name: 'technician' },
      { name: 'customer' },
      { name: 'guest' },
    ],
    headers: [
      { text: 'id', align: 'start', value: 'id' },
      { text: 'Avatar', sortable: false },
      { text: 'Name', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: 'Phone', value: 'phone' },
      { text: 'Type', value: 'type' },
      { text: 'Status', value: 'status' },
    ],
    apikey: `${process.env.VUE_APP_API_KEY_MAPS}`,
    dialog: false,
    users: [],
    show1: false,
    passRules: [
      (v) => !!v || 'Enter password....',
      (v) => (v && v.length >= 6) || 'Password must be more than 6 characters',
    ],
    itemsPerPage: 25,
    color: null,
    paginate: false,
    loader: false,
    password: null,
    page: 1,
    type: null,
    disabled: false,
    status: false,
    pagination: 1,
    toggle_multiple: [],
    optionsType: [
      { name: 'Super Administrador', value: 'superadmin' },
      { name: 'Admin', value: 'admin' },
      { name: 'Technician', value: 'technician' },
      { name: 'Manager', value: 'manager' },
      { name: 'Customer', value: 'customer' },
      { name: 'Guest', value: 'guest' },
    ],
    colors: [
      'blue',
      'indigo',
      'deep-purple',
      'cyan',
      'green',
      'orange',
      'grey darken-1',
    ],
    name: null,
    letter: null,
    employ: null,
    email: null,
    edit: false,
    phone: null,
    userSelect: false,
    company: null,
    searchRol: false,
    address: null,
    note: null,
    errors: null,
    message: false,
    btnloader: false,
    idUSer: null,
    rules: {
      required: (value) => !!value || 'Required.',
      counter: (value) => value.length <= 20 || 'Max 20 characters',
      email: (value) => /.+@.+\..+/.test(value) || 'Invalid e-mail.',
    },
  }),
  mounted() {
    this.getUsers();
  },
  computed: {
    ...mapState(['search', 'token', 'roles', 'techs', 'clients', 'managers']),
  },
  watch: {
    notGmail() {
      this.password = null;
    },
    page() {
      if (this.searchRol) {
        this.getUserbyRol(this.type);
      } else {
        this.getUsers();
      }
    },
    search() {
      this.getSearch();
    },
  },
  components: {
    Multiselect,
  },
  methods: {
    ...mapMutations(['SET_ROLES']),

    getValidate() {
      if (!this.name) {
        this.errors.push('El nombre de usuario es obligatorio.');
      }
      if (!this.email) {
        this.errors.push('El email de usuario es obligatorio.');
      }
      if (!this.employ) {
        this.errors.push('El employ de usuario es obligatorio.');
      }
      if (!this.phone) {
        this.errors.push('El phone de usuario es obligatorio.');
      }
      if (!this.company) {
        this.errors.push('El company de usuario es obligatorio.');
      }
      if (!this.address) {
        this.errors.push('El address de usuario es obligatorio.');
      }
      if (!this.type) {
        this.errors.push('El type de usuario es obligatorio.');
      }
    },
    validate() {
      this.errors = [];
      if (
        !this.name ||
        !this.employ ||
        !this.email ||
        !this.phone ||
        !this.address ||
        !this.company ||
        !this.type
      ) {
        this.message = true;
        this.getValidate();
      } else {
        this.message = false;
        this.create();
      }
    },
    onPlaceChanged(value) {
      this.address = value.formatted_address;
    },
    clear() {
      this.edit = false;
      this.disabled = false;
      this.idUSer = null;
      this.name = null;
      this.employ = null;
      this.email = null;
      this.phone = null;
      this.address = null;
      this.company = null;
      this.type = null;
      this.note = null;
      this.errors = [];
      this.message = false;
      this.password = null;
    },
    updateuser() {
      this.btnloader = true;
      let post = {
        id: this.idUSer,
        name: this.name,
        employ: this.employ,
        email: this.email,
        phone: this.phone,
        address: this.address,
        company: this.company,
        type: this.type.value,
        note: this.note,
        pass: this.password,
      };

      apis.sendPost('/update-user', post).then((reponse) => {
        this.$swal.fire('User Updated', '', 'success');
        this.users = reponse.data;
        this.btnloader = false;
        this.dialog = false;
        this.clear();
      });
    },
    edirUser(item) {
      this.disabled = true;
      this.idUSer = item.id;
      this.name = item.name;
      this.employ = item.employ;
      this.email = item.email;
      this.phone = item.phone;
      this.address = item.address;
      this.company = item.company;
      this.type = { name: item.type, value: item.type };
      this.note = item.note;
    },
    create() {
      this.btnloader = true;
      let post = {
        name: this.name,
        employ: this.employ,
        email: this.email,
        phone: this.phone,
        address: this.address,
        company: this.company,
        type: this.type.value,
        note: this.note,
        pass: this.password,
        color: this.colors[this.rnd(0, this.colors.length - 1)],
      };

      apis
        .sendPost('/add-user', post)
        .then((reponse) => {
          if (reponse.data === false) {
            this.$swal.fire('User Duplicated', '', 'error');
          } else {
            this.$swal.fire('User Created', '', 'success');
            this.dialog = false;
            this.clear();
          }
          this.btnloader = false;
        })
        .catch((error) => {
          this.btnloader = false;
          this.$swal.fire('User Duplicated', '', error);
        });
    },
    changStatus(status, id) {
      let post = {
        id: id,
        status: status,
      };
      apis.sendPost('/changeStatus', post).then(() => {
        this.$swal.fire('Satatus Changed', '', 'success');
      });
    },
    apiGetUsers(url) {
      this.loader = true;
      this.paginate = true;
      apis.getUrl(url).then((reponse) => {
        this.users = reponse.data;
        this.pagination = reponse.data.last_page;
        this.paginate = false;
        this.loader = false;
      });
    },
    getUserbyRol(type) {
      const url = `/getUserbyRol/${type}?page=${this.page}&per_page=25`;
      this.apiGetUsers(url);
    },
    getSearch() {
      const url = `/users/search/${this.search}?page=${this.page}&per_page=25`;
      this.apiGetUsers(url);
    },
    getUsers() {
      const url = `/users?page=${this.page}&per_page=25`;
      this.apiGetUsers(url);
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.div-email {
  display: flex;
  align-items: center;
}
.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 30px;
  min-height: 0;
  margin: 5px;
  min-width: 32px;
  border: azure;
  border-radius: 9px;
}
.v-btn-toggle > .v-btn.v-btn--active {
  color: #003790 !important;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 36px;
  padding: 0px 3px;
}
.user-btn-close {
  right: 0px;
  position: absolute;
  top: 0;
}
.tittle-Table {
  background-color: #081b38;
  color: white;
  margin: 0px;
  border-radius: 15px 15px 0px 0px;
}
.table-style {
  padding: 0px;
}
.add-proyect {
  bottom: 6% !important;
  right: 4% !important;
  position: fixed;
}
.card-user {
  width: 70%;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.tel {
  text-decoration-line: none;
  color: black !important;
}
.ikon {
  font-size: 15px;
  color: #081b38;
}
.pointeTable {
  cursor: pointer;
}
</style>
