<template>
  <div>
    <vue-dropzone style="width: 100%; background: #5256ad; color: white; height: 150px; padding: 5px 5px; overflow: auto;"
      ref="myVueDropzone2" id="customdropzone2" :options="options" v-on:vdropzone-sending="sendingEvent"
      :useCustomSlot="true" @vdropzone-queue-complete="complete" @vdropzone-success="success">


      <div class="dropzone-custom-content2">
        <v-icon color="white" style="font-size: 49px;">mdi-cloud-upload</v-icon>
        <h4 class="dropzone-custom-title">Drag & drop to upload file!</h4>
        <div class="subtitle">
          ...or click to select a file from your computer
        </div>
      </div>
    </vue-dropzone>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import { mapState } from 'vuex';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
export default {
  name: 'app',
  components: {
    vueDropzone: vue2Dropzone,
  },
  data: function () {
    return {
      id: null,
      images: [],
      names: [],
    };
  },
  props: {
    type: {
      type: String,
      require: true,
    },
  },
  computed: {
    ...mapState(['user', 'token', 'ticket', 'label', 'serviceSelected']),
    options() {
      return {
        url: process.env.VUE_APP_S3,
        thumbnailWidth: 270,
        maxFilesize: 20,
        headers: {
          'Content-Type': 'fileType',
          'x-amz-acl': 'public-read',
        },
        dictDefaultMessage:
          " <p class='text-upl'>Drag & Drop <br> <small class='smalltext'>Your File to upload, or browse</small></p> <br> <h2 class='text-upl h2dropzone'>. . .</h2> <small class='smalltext'>upload your proyect images</small>",
        acceptedFiles: 'image/*,application/pdf,document/*,video/*,audio/*',
      };
    },
  },
  methods: {
    async sendingEvent(file, xhr) {
      try {
        const fileName = uuidv4();
        const fileType = file.type;
        const fileRealName = file.name;
        const format = fileType.split('/')[1];
        const imageUrl = `${process.env.VUE_APP_S3}/tmp/${fileName}.${format}`;

        xhr.timeout = 60000;
        xhr.setRequestHeader('Content-Type', fileType);
        xhr.open('PUT', imageUrl, true);
        xhr.send(file);
        this.names.push(fileRealName);
        this.images.push(`tmp/${fileName}.${format}`);
      } catch (error) {
        this.$swal.fire('Error al cargar el archivo:', JSON.stringify(error), 'error');
      }
    },
    success(file, response) {
      this.$emit('updateImage', response);
    },
    async complete() {
      try {
        const url = this.type === 'ticket'
          ? `${process.env.VUE_APP_BASE_URL_API}/api/v1/image/uploadTicket`
          : `${process.env.VUE_APP_BASE_URL_API}/api/v1/image/upload`;

        const id = this.type === 'ticket' ? this.ticket.id : this.serviceSelected.id;

        const data = {
          id: id,
          id_user: this.user.id,
          label: this.label,
          names: this.names,
          images: this.images,
        };

        this.$swal.fire({
          title: 'Procesando Archivos',
          html: 'Se cerrará esta ventana cuando finalice.',
          timerProgressBar: true,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });

        const response = await axios.post(url, data, {
          headers: {
            Authorization: `Bearer ${this.token}`,
            Accept: 'application/json',
          },
        });

        this.$emit('updateImage', response);
        this.$swal.close();
        this.$swal.fire(`${response.data.Success}`, '', 'success');
      } catch (error) {
        console.error(error);
        this.$swal.fire('Error', error.message, 'error');
      }

      setTimeout(() => this.removeAllFiles(), 500);
    },
    async removeAllFiles() {
      await this.$refs.myVueDropzone2.removeAllFiles();
      this.$emit('activeShowGalery', true);
    },

    template: function () {
      return `<div class='dz-preview2 dz-file-preview'>
                <div class='dz-image'>
                    <div data-dz-thumbnail-bg></div>
                </div>
                <div class='dz-details'>
                    <div class='dz-size'><span data-dz-size></span></div>
                    <div class='dz-filename'><span data-dz-name></span></div>
                </div>
                <div class='dz-progress'><span class='dz-upload' data-dz-uploadprogress></span></div>
                <div class='dz-error-message'><span data-dz-errormessage></span></div>
                <div class='dz-success-mark'><i class='fa fa-check'></i></div>
                <div class='dz-error-mark'><i class='fa fa-close'></i></div>
            </div>
        `;
    },
    thumbnail: function (file, dataUrl) {
      var j, len, ref, thumbnailElement;
      if (file.previewElement) {
        file.previewElement.classList.remove('dz-file-preview');
        ref = file.previewElement.querySelectorAll('[data-dz-thumbnail-bg]');
        for (j = 0, len = ref.length; j < len; j++) {
          thumbnailElement = ref[j];
          thumbnailElement.alt = file.name;
          thumbnailElement.style.backgroundImage = 'url(' + dataUrl + ')';
        }
        return file.previewElement.classList.add('dz-image-preview');
      }
    },
  },
};
</script>
<style>
.dropzone-custom-content2 {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-content: center !important;
  align-items: center !important;
  height: 100% !important;
  border: 2px dashed white !important;
}

.dropzone .dz-message {
  height: 99% !important;
  margin: 0 !important;
}

#customdropzone2 {
  border: 2px dashed white !important;
  background: #5256ad;
}

#customdropzone2 .dz-preview2 {
  display: inline-block;
  margin: 0;
  background: #5256ad;
}

#customdropzone2 .dz-preview2 .dz-image {
  width: 80px;
  height: 80px;
  background: #5256ad;
}

#customdropzone2 .dz-preview2 .dz-image>div {
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-size: auto;
}

#customdropzone2 .dz-preview2 .dz-image>img {
  width: 100%;
}

#customdropzone2 .dz-preview2 .dz-details {
  color: white;
  transition: opacity 0.2s linear;
  text-align: center;
}

#customdropzone2 .dz-success-mark,
.dz-error-mark,
.dz-remove {
  display: none;
}
</style>
