<template>
    <v-container justify="center">
      <v-expand-transition>
        <v-alert type="success" v-if="message">
          Record created successfully.
        </v-alert>
      </v-expand-transition>
      <v-row>
        <v-col sm="10" md="8" align-self="center">
          <v-btn-toggle v-model="toggle_multiple" class="scroll">
            <v-btn
              depressed
              v-for="(statu, i) in status_ticket"
              :key="i"
              @click="getInit(statu.id, page), (id_status = statu.id)"
            >
              {{ statu.name }} ({{ statu.count }})
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col dense sm="6" md="auto" align-self="center">
          <v-btn
            @click="
              setSearch(null),
                (isSearch = false),
                getInit(idStatusProject, 1),
                (page = 1)
            "
          >
            <v-icon color="#081b38">mdi-refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col dense sm="6" md="auto" align-self="center">
          <div class="text-center">
            <v-skeleton-loader
              v-if="paginate"
              type="card-avatar, article, actions"
              height="30px"
              width="100%"
            ></v-skeleton-loader>
            <v-pagination
              v-else
              v-model="page"
              @click="getInit(id_status, page)"
              :length="pagination"
              color="#081b38"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="tittle-Table">
          <h2>Current Projects</h2>
        </v-col>
        <v-col class="table-style">
          <v-data-table
            :headers="headers"
            :items="projects"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            :loading="loader"
            hide-default-footer
            class="elevation-7"
            width="100%"
          >
            <template v-slot:item="{ item }">
              <tr class="pointeTable" @click="gotopage(item)">
                <td class="hidden-xs-only">
                  {{ item.id }}
                </td>
                <td>
                  {{ item.name }}
                </td>
                <td class="hidden-xs-only">{{ getDate(item.created_at) }}</td>
                <td>
                  {{ item.address }}
                </td>
                <td class="hidden-xs-only" v-if="item.tickets.length > 0">
                  <div v-for="(ticket, x) in item.tickets" :key="x">
                    <p class="ticket">
                      <strong>PO:</strong> #{{ ticket.id }} -
                      <strong>Status:</strong> {{ ticket.status.name }}
                    </p>
                  </div>
                </td>
                <td class="hidden-xs-only" v-else>
                  <div>
                    <p class="ticket">There are no Pos yet</p>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col dense xs="6" md="auto" align-self="center">
          <div class="text-center">
            <v-skeleton-loader
              v-if="paginate"
              type="card-avatar, article, actions"
              height="30px"
              width="100%"
            ></v-skeleton-loader>
            <v-pagination
              v-else
              v-model="page"
              @click="getInit(id_status, page)"
              :length="pagination"
              color="#081b38"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
      <NewProject />
    </v-container>
</template>

<script>
import NewProject from '@/components/NewProject.vue';
import { mapState, mapMutations } from 'vuex';
import { FORMAT_DATE } from '@/constants';
import apis from '@/apis';
import moment from 'moment';
export default {
  data: () => ({
    page: 1,
    id: null,
    editService: false,
    id_status: 0,
    isSearch: false,
    paginate: false,
    filter_id: null,
    toggle_multiple: 0,
    pagination: 1,
    itemsPerPage: 25,
    message: false,
    loader: false,
    address: null,
    headers: [
      { text: 'Proyect #', align: 'start', value: 'id' },
      { text: 'Proyect Name', value: 'name' },
      { text: 'Created on', value: 'start' },
      { text: 'Address', value: 'address' },
      { text: 'POs', value: 'PO' },
    ],
  }),
  computed: {
    ...mapState([
      'token',
      'status_ticket',
      'projectActives',
      'projects',
      'search',
      'user',
      'idStatusProject',
      'toggleMultiple',
    ]),
  },
  components: {
    NewProject,
  },
  mounted() {
    if (this.user.type != 'technician') {
      if (this.search) {
        this.isSearch = true;
      }
      this.toggle_multiple = this.toggleMultiple;
      this.id_status = this.idStatusProject;
      this.getInit(this.id_status, 1);
    }
  },
  watch: {
    search() {
      if (this.search) {
        this.isSearch = true;
        this.getInit(0, 1);
      }
    },
    id_status() {
      this.setIdStatusProject(this.id_status);
    },
    toggle_multiple() {
      this.setToggleMultiple(this.toggle_multiple);
    },
    page() {
      this.getInit(this.id_status, this.page);
    },
  },
  methods: {
    ...mapMutations([
      'setProjects',
      'setproject',
      'setSearch',
      'setIdStatus',
      'setFilterProject',
      'setIdStatusProject',
      'setToggleMultiple',
    ]),
    getDate(date) {
      return moment(date).format(FORMAT_DATE);
    },
    gotopage(item) {
      this.setproject(item);
      this.$router.push(`/projects/${item.id}`);
    },
    getSearch() {
      this.loader = false;
      this.isSearch = false;
      this.setSearch(null);
    },
    getInit(id, page) {
      this.setFilterProject(id);
      this.loader = true;
      var route;
      if (this.isSearch) {
        route = `/projects/search/${this.search}?page=${this.page}`;
      } else {
        if (!id) {
          route = `/projects?page=${page}&per_page=${this.itemsPerPage}`;
        } else {
          route = `/projects?page=${page}&per_page=${this.itemsPerPage}&status=${id}`;
        }
      }
      apis.getUrl(route).then((reponse) => {
        this.setProjects(reponse.data.data);
        this.pagination = reponse.data.last_page;
        this.loader = false;
      });
    },
  },
};
</script>

<style>
.ticket {
  margin-bottom: 9px !important;
  margin-left: 9px !important;
  margin-right: 9px !important;
  margin-top: 9px !important;
}
@media (min-width: 1264px) {
  .container {
    max-width: 96%;
  }
}
@media (min-width: 960px) {
  .container {
    max-width: 96%;
  }
}
.pointeTable {
  cursor: pointer;
}
.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 30px;
  min-height: 0;
  margin: 5px;
  min-width: 32px;
  border: azure;
  border-radius: 9px;
}
.v-btn-toggle > .v-btn.v-btn--active {
  color: #003790 !important;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 36px;
  padding: 0px 3px;
}
.tittle-Table {
  background-color: #081b38;
  color: white;
  margin: 0px;
  border-radius: 15px 15px 0px 0px;
}
.table-style {
  padding: 0px;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  color: #081b38;
  font-size: 15px;
}
.scroll {
  overflow-y: hidden;
  overflow-x: auto;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
  background: white;
}
</style>
