<template>
  <v-container class="services" justify="center">
    <div v-if="loaderPage">
      <v-col cols="12" class="tittle-Table" v-if="user.type != 'technician'">
        <v-btn @click="returntopage()" class="to">
          <v-icon color="#081b38">mdi-arrow-left-bold</v-icon>
        </v-btn>
        <h2>Loading...</h2>
        <v-btn color="success" elevation="5" class="to" depressed disabled>
          Edit <v-icon color="white">mdi-pencil</v-icon>
        </v-btn>
      </v-col>
    </div>
    <div v-else>
      <div v-if="galleriesById.length > 0">
        <LightBox ref="lightbox" :media="galleriesById" :show-caption="true" :show-light-box="false"
          :site-loading="siteLoading" />
      </div>
      <v-col cols="12" class="tittle-Table" v-if="user.type != 'technician'">
        <v-btn @click="returntopage()" class="to">
          <v-icon color="#081b38">mdi-arrow-left-bold</v-icon>
        </v-btn>
        <h2>Proyect {{ projectSelected.name }}</h2>
        <v-btn v-if="!editService" color="success" elevation="5" class="to" depressed
          @click="editService = !editService">
          Edit <v-icon color="white">mdi-pencil</v-icon>
        </v-btn>
        <v-btn v-else class="to" color="warning" elevation="5" depressed @click="editService = !editService">
          cancel <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-col>
      <div v-if="user.type != 'technician'">
        <v-expand-transition v-if="editService">
          <v-form class="form-edit" ref="form" v-model="valid" lazy-validation>
            <v-text-field v-model="name" :rules="nameRules" label="Project/Service Name"
              prepend-icon="mdi-clipboard-text" required></v-text-field>

            <v-col cols="12" class="col-account">
              <div>
                <v-icon class="v-icon icon icono">mdi-google-maps</v-icon>
              </div>
              <div class="label-multiselect">
                <VueGooglePlaces :api-key="apikey" @placechanged="onPlaceChanged" :options="{ fields: ['geometry'] }"
                  country="usa">
                  <v-text-field label="Enter an an address, zipcode, or location" v-model="address" :rules="emailRules"
                    hide-details="auto"></v-text-field>
                </VueGooglePlaces>
              </div>
            </v-col>

            <v-col cols="12" class="col-account">
              <v-select v-model="client" :items="clients" :item-value="'id'" item-text="name" menu-props="auto"
                label="Assign Client" hide-details color="#003790" prepend-icon="mdi-account" single-line></v-select>
            </v-col>
            <v-col cols="12">
              <v-select v-model="statusSet" :items="status_ticket" item-text="name" :item-value="'id'" label="Status"
                color="#003790" prepend-icon="mdi-list-status" persistent-hint></v-select>
            </v-col>

            <v-col cols="12">
              <vue-editor v-model="content" id="editor" name="description"></vue-editor>
            </v-col>

            <v-btn :disabled="!valid" color="success" :loading="btnloader" class="mr-4" @click="validate">
              Save
            </v-btn>
            <v-btn color="error" elevation="5" depressed @click="openModalDelete()">
              Delete<v-icon color="white">mdi-delete</v-icon>
            </v-btn>
          </v-form>
        </v-expand-transition>
        <v-expand-transition v-else>
          <v-card class="card-des" elevation="16">
            <div class="status">
              <div class="status-divs">
                <p>
                  Client:
                </p>
                <v-col class="d-flex" cols="12" sm="2">
                  <strong>
                    <p>{{ projectSelected.customer.name }}</p>
                  </strong>
                </v-col>
                <p>
                  Email:
                </p>
                <v-col class="d-flex" cols="12" sm="3">
                  <strong>
                    <p>{{ projectSelected.customer.email }}</p>
                  </strong>
                </v-col>
                <p>
                  Phone:
                </p>
                <v-col class="d-flex" cols="12" sm="2">
                  <strong>
                    <p>{{ projectSelected.customer.phone }}</p>
                  </strong>
                </v-col>
              </div>
            </div>
            <v-row>
              <v-col cols="12" sm="7">
                <h1>Description:</h1>
                <p v-html="projectSelected.description"></p>
              </v-col>
              <v-col cols="12" sm="5">
                <div v-show="map">
                  <gmap-map :center="center" :zoom="16" class="d-none d-sm-flex"
                    style="width: 90%; height: 350px; left: 0; right: 0; margin-left: auto; margin-right: auto;">
                    <gmap-marker :key="index" v-for="(m, index) in markers" :position="m.position" :title="m.title"
                      :clickable="true" :draggable="true" @click="center = m.position"></gmap-marker>
                  </gmap-map>
                  <h3 class="direction" @click="goMap()">
                    {{ projectSelected.address }}
                  </h3>
                </div>

                <div v-if="hasAccess">

                  <p class="proyect">
                    Proposals
                    <v-icon color="#182a45">mdi-clipboard-text-multiple</v-icon>
                  </p>
                  <Estimate :estimate="selectEstimate" @newEstimate="newEstimate"
                    @updateEstimates="getProjectId($route.params.id)" ref="estimateModal" />
                  <div v-for="(estimate, i) in projectSelected.estimates" :key="i" class="note-acount">
                    <div @click="modalEstimate(estimate)" class="estimate">
                      <v-avatar size="26px">
                        <img alt="Avatar" :src="estimate.user.avatar" />
                      </v-avatar>
                      <p class="name-note">
                        {{ estimate.user.name }}
                      </p>
                      <p class="name-note">
                        #{{ projectSelected.id }}- {{ estimate.id }}
                      </p>
                      <p class="name-note">
                        {{ JSON.parse(estimate.data).total }}
                      </p>
                      <p class="name-note">
                        {{ getFormatDate(JSON.parse(estimate.data).date) }}
                      </p>
                    </div>

                    <v-btn icon dark @click="estimatePdf(estimate.id)">
                      <v-icon color="#081b38">mdi-file-pdf-box</v-icon>
                    </v-btn>
                    <v-btn icon dark @click="deleteEstimate(estimate.id)">
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </div>
                <div>
                  <div class="project-upload">
                    <h5>Upload Files/Images</h5>
                    <Upload />
                  </div>

                  <h5>Files/Images</h5>
                  <div class="files-div">
                    <v-chip style="width: 45%;" v-for="(docs, i) in files" :key="i" class="ma-2" color="blue"
                      text-color="white" close @click="sendUrl(docs.url)" @click:close="
      deleteMultimedia(docs.id), (pdfURL = docs.url)
      ">
                      <v-icon left>
                        mdi-pdf-box
                      </v-icon>
                      <p class="p-document">{{ getPdfName(docs.data) }}</p>
                    </v-chip>
                  </div>
                  <v-carousel hide-delimiters :show-arrows="true" height="300" v-if="imgs.length >= 1">
                    <v-carousel-item v-for="(item, i) in imgs" :key="i" @click="openGallery(item.id)" :src="'https://systemgj.s3-us-west-1.amazonaws.com/' + item.url
      "></v-carousel-item>
                  </v-carousel>
                  <v-btn class="btn-d" v-if="imgs.length >= 1" color="#081b38" @click="downloadAllimages()">
                    <v-icon color="white" class="note-p">mdi-download</v-icon>
                    Download Pictures
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card></v-expand-transition>
      </div>

      <v-expand-transition>
        <v-alert type="success" v-if="message">
          Record created successfully.
        </v-alert>
      </v-expand-transition>
      <v-row> </v-row>
      <v-row>
        <v-col cols="12" class="tittle-Table">
          <h2>PO s</h2>
        </v-col>
        <v-col class="table-style">
          <v-data-table :headers="headers" :items="tickets" :page.sync="page" :items-per-page="itemsPerPage"
            :loading="loader" hide-default-footer class="elevation-7" width="100%">
            <template v-slot:item="{ item }">
              <tr class="pointeTable" @click="gotopage(item)">
                <td class="hidden-xs-only">
                  {{ item.id }}
                </td>
                <td class="hidden-xs-only">
                  {{ item.po_purchase }}
                </td>
                <td>
                  {{ item.so }}
                </td>
                <td class="hidden-xs-only">
                  {{ getDate(item.start, item.created_at) }}
                </td>
                <td class="hidden-xs-only">
                  <p v-for="(tech, i) in item.users" :key="i">
                    <small v-if="tech">{{ tech.name }}</small>
                  </p>
                </td>
                <td>
                  {{ item.manager.name }}
                </td>
                <td>
                  {{ item.status.name }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row class="logs" v-if="user.type != 'technician'">
        <p class="proyect">
          Project Logs
          <v-icon color="#182a45">mdi-format-list-bulleted </v-icon>
        </p>
        <v-btn v-if="!expandLogs" @click="expandLogs = !expandLogs" elevation="2" icon class="btn-dismis">
          <v-icon dark color="#081b38">
            mdi-plus
          </v-icon>
        </v-btn>
        <v-btn v-else @click="expandLogs = !expandLogs" elevation="2" icon class="btn-dismis">
          <v-icon dark color="#081b38">
            mdi-minus
          </v-icon>
        </v-btn>
      </v-row>
      <v-row>
        <v-col v-if="expandLogs">
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }">
              <div class="attachments">
                <v-expand-transition>
                  <div style="padding: 30px;">
                    <div v-if="statusHistoryProject.length > 0">
                      <v-timeline reverse dense>
                        <v-timeline-item v-for="(history, n) in statusHistoryProject" :key="n" small fill-dot
                          color="#182a45">
                          <v-card class="elevation-2" height="100%">
                            <v-card-title class="log-name">
                              <small>{{ history.value.split('"')[3] }} </small>
                            </v-card-title>
                            <v-card-text class="log-tex">
                              <p class="log-description">
                                <strong>Description: </strong>
                                {{ history.value.split('"')[7] }}
                              </p>
                              <p>
                                <strong>
                                  Message:
                                </strong>
                                {{ history.value.split('"')[11] }}
                              </p>
                              <br />
                              {{ date(history.created_at) }}
                            </v-card-text>
                          </v-card>
                        </v-timeline-item>
                      </v-timeline>
                    </div>
                    <div v-else>
                      <v-col md="12" cols="12">
                        <div>
                          <h3>
                            Loading...
                          </h3>
                        </div>
                      </v-col>
                    </div>
                  </div>
                </v-expand-transition>
              </div>
            </v-card></v-hover>
        </v-col>
      </v-row>

      <NewProject v-if="user.type != 'technician'" />

    </div>
  </v-container>
</template>

<script>
require('vue-image-lightbox/dist/vue-image-lightbox.min.css');
import LightBox from 'vue-image-lightbox';
import siteLoading from 'vue-image-lightbox/src/siteloading.gif';
import NewProject from '@/components/NewTicket.vue';
import { FORMAT_DATE } from '@/constants';
import { mapState, mapMutations } from 'vuex';
import apis from '@/apis';
import google from '@/apis/google';
import { VueEditor } from 'vue2-editor';
import moment from 'moment';
import Estimate from '@/components/EstimateProject.vue';
import Upload from '@/components/UploadFiles';
import { setTimeout } from 'timers';
import axios from 'axios';
export default {
  data: () => ({
    page: 1,
    galleriesById: [],
    expandLogs: false,
    id: null,
    total: 0,
    history: [],
    error: false,
    content: null,
    statusget: null,
    apikey: `${process.env.VUE_APP_API_KEY_MAPS}`,
    noteStatus: null,
    statusEnable: false,
    editService: false,
    id_status: null,
    client: null,
    paginate: false,
    siteLoading,
    tab: false,
    filter_id: null,
    deleteLoader: false,
    pagination: 1,
    selectEstimate: [],
    inspection: null,
    center: { lat: 20.0396296, lng: -100.7298147 },
    itemsPerPage: 25,
    statusSet: 9,
    search: false,
    btnloader: false,
    message: false,
    toggle_multiple: 0,
    loader: false,
    address: null,
    headers: [
      { text: '#', align: 'start', value: 'id' },
      { text: 'PO #', value: 'po' },
      { text: 'SO #', value: 'so' },
      { text: 'Created on', value: 'start' },
      { text: 'Techs', value: 'tech' },
      { text: 'PM/SC/PE', value: 'manager' },
      { text: 'Status', value: 'status' },
    ],
    map: false,
    markers: [],
    valid: true,
    name: '',
    nameRules: [(v) => !!v || 'Name is required'],
    email: '',
    emailRules: [(v) => !!v || 'Address is required'],
    select: null,
    items: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
    checkbox: false,
    loaderPage: true,
  }),
  computed: {
    ...mapState([
      'token',
      'status_ticket',
      'projectSelected',
      'clients',
      'lat',
      'lng',
      'tickets',
      'user',
      'projects',
      'statusHistoryProject',
    ]),
    hasAccess() {
      return this.user.type === 'superadmin' || this.user.type === 'admin';
    },
    files() {
      var files = [];
      this.projectSelected.files.map((file) => {
        if (file.type == 'document') {
          files.unshift(file);
        }
      });
      return files;
    },
    imgs() {
      var imgas = [];
      this.projectSelected.files.map((file) => {
        if (file.type == 'image') {
          imgas.unshift(file);
        }
      });
      return imgas;
    },
  },
  components: {
    VueEditor,
    Estimate,
    NewProject,
    Upload,
    LightBox,
  },
  mounted() {
    this.getProjectId(this.$route.params.id);
    this.setStatusHistoryProject([]);
  },
  watch: {

    expandLogs() {
      if (this.expandLogs) {
        apis.logsProjects(this.projectSelected.id).then((data) => {
          this.setStatusHistoryProject(data.data.statusHistory);
        });
      }
    },
    imgs() {
      this.galleriesById = this.getGalleryLigthbox([...this.imgs]);
    },
  },
  methods: {
    ...mapMutations([
      'setStatusProject',
      'setStatusProjects',
      'addProject',
      'setTicket',
      'setLocation',
      'setTickets',
      'setproject',
      'setStatusHistoryProject',
    ]),

    async downloadAllimages() {
      this.$swal.fire({
        title: 'Procesando Imagenes',
        html: 'Se cerrará esta ventana cuando finalice.',
        timerProgressBar: true,
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      const url = `${process.env.VUE_APP_BASE_URL_API}/api/v1/downloadAllimgProject/${this.projectSelected.id}/project`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${this.token}`,
          Accept: 'application/json',
        },
      });
      this.$swal.close();
      this.$swal.fire(`${response.data.msg}`, '', 'success');
    },
    getProjectId(idProject) {
      this.loaderPage = true;
      apis.getProject(idProject).then((response) => {
        this.setproject(response.data);
        this.loaderPage = false;
        this.getInit();
        this.setTickets([]);

        this.statusSet = this.projectSelected.status_id;
        this.statusget = this.projectSelected.status_id;
        this.name = this.projectSelected.name;
        this.address = this.projectSelected.address;
        this.content = this.projectSelected.description;

        this.center = { lat: this.lat, lng: this.lng };

        const index = this.clients.findIndex(
          ({ id }) => id == this.projectSelected.customer_id,
        );

        this.client = this.clients[index].id;
        this.galleriesById = this.getGalleryLigthbox([...this.imgs]);
        google.geocode(this.projectSelected.address).then((res) => {
          this.setLocation({
            lat: res.data.results[0].geometry.location.lat,
            lng: res.data.results[0].geometry.location.lng,
          });
          this.center = { lat: res.data.results[0].geometry.location.lat, lng: res.data.results[0].geometry.location.lng };
          this.markers = [
            {
              position: { lat: res.data.results[0].geometry.location.lat, lng: res.data.results[0].geometry.location.lng },
              title: this.projectSelected.name,
            },
          ];
          setTimeout(() => {
            this.map = true;
          }, 1000);
        });
      });
    },
    getGalleryLigthbox(galleries) {
      if (!galleries) return [];
      return galleries.map((gallery) => {
        return {
          id: gallery.id,
          selected: gallery.selected,
          thumb: `https://systemgj.s3-us-west-1.amazonaws.com/${gallery.url}`,
          src: `https://systemgj.s3-us-west-1.amazonaws.com/${gallery.url}`,
          caption: gallery.caption,
        };
      });
    },
    getPdfName(data) {
      var name = JSON.parse(data);
      return name.name;
    },
    estimatePdf(id) {
      window.open(
        `${process.env.VUE_APP_BASE_URL_API}/pdf/${id}?type=project`,
        '_blank',
      );
    },
    modalEstimate(estimate) {
      this.selectEstimate = estimate;
      this.$refs.estimateModal.dialog = true;
    },
    newEstimate() {
      if (this.projectSelected.estimates.length > 0) {
        let lastEstimate = this.projectSelected.estimates[this.projectSelected.estimates.length - 1];
        this.selectEstimate = { ...lastEstimate, id: null };
      } else {
        this.selectEstimate = { id: null, date: new Date() };
      }
    },
    deleteEstimate(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: 'You wont be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            apis.getUrl(`/estimates/${id}`).then(() => {
              this.$swal.fire('Estimate Deleted!', '', 'success');
            });
          }
        });
    },

    sendUrl(url) {
      if (url.includes('drive.google.com')) {
        window.open(url, '_blank');
      } else {
        window.open(
          'https://systemgj.s3-us-west-1.amazonaws.com/' + url,
          '_blank',
        );
      }
    },
    deleteMultimedia(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: 'You wont be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            apis.sendDelete(`/multimedias/${id}`).then(() => {
              this.$swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success',
              );
              this.deleteLoader = false;
              this.dialog = false;
            });
          }
        });
    },
    goMap() {
      window.open(
        `https://www.google.com/maps/place/${this.projectSelected.address}`,
        '_blank',
      );
    },
    getDate(date, created_at) {
      if (date) {
        return moment(date).format(FORMAT_DATE);
      } else {
        return moment(created_at).format(FORMAT_DATE);
      }
    },
    date(date) {
      return moment(date).format('LLLL');
    },
    getFormatDate(date) {
      return moment(date).format(FORMAT_DATE);
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.edit();
      }
    },
    reset() {
      this.$refs.form.reset();
    },

    openGallery(index) {
      const indexOld = this.galleriesById.findIndex(({ id }) => id === index);
      this.$refs.lightbox.showImage(indexOld);
    },
    openModalDelete() {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: 'You wont be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            apis
              .sendDelete(`/delete-project/${this.projectSelected.id}`)
              .then(() => {
                this.$swal.fire(
                  'Deleted!',
                  'Your Service has been deleted.',
                  'success',
                );
                this.$router.push('/projects');
              });
          }
        });
      this.loading = true;
    },
    edit() {
      this.btnloader = true;
      let post = {
        name: this.name,
        customer: this.client,
        address: this.address,
        description: this.content,
        statusId: this.statusSet,
      };
      apis
        .sendPatch(`/projects/${this.projectSelected.id}`, post)
        .then(() => {
          this.$swal.fire(
            'Updated!',
            'Your Service has been updated.',
            'success',
          );
          this.editService = false;
          this.btnloader = false;
          this.projectSelected.name = this.name;
          this.projectSelected.address = this.address;
          this.projectSelected.description = this.content;
        })
        .catch((error) => {
          this.btnloader = false;
          this.$swal.fire('Error!', error, 'error');
        });
    },
    openModal(option) {
      this.$refs.usermodal.option = option;
      this.$refs.usermodal.dialog = true;
    },
    onPlaceChanged(value) {
      this.address = value.formatted_address;
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    gotopage(item) {
      this.setTicket(item);
      this.$router.push(`/projects/${this.$route.params.id}/${item.id}`);
    },
    returntopage() {
      this.setTickets([]);
      this.$router.push(`/projects`);
    },
    changeStatus() {
      this.deleteLoader = true;
      if (this.noteStatus === null) {
        this.error = true;
        this.deleteLoader = false;
      } else {
        this.error = false;
        let formData = new FormData();
        formData.append('statusId', this.statusSet);
        formData.append('project_id', this.$route.params.id);
        formData.append('message', this.noteStatus);
        let headers = {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
          Accept: 'application/json',
        };
        apis
          .sendForm('/statusProject', formData, headers)
          .then((data) => {
            this.deleteLoader = false;
            this.$swal.fire('Status Saved!', '', 'success');
            this.statusEnable = false;
            this.noteStatus = null;
            this.setStatusProject({
              oldValue: this.projectSelected.status_id,
              newValue: data.data.status_id,
            });
            this.statusget = this.statusSet;
          })
          .catch((error) => {
            this.deleteLoader = false;
            this.$swal.fire('Error!', error, 'error');
          });
      }
    },
    count(data) {
      var total = 0;
      data.map((status) => {
        total = total + parseInt(status.count);
      });

      this.total = total;
    },
    getInit(id, page) {
      this.loader = true;
      var route;
      if (this.search) {
        this.getSearch();
      } else {
        if (!id) {
          route = `/tickets/${this.projectSelected.id}?page=${page}&per_page=${this.itemsPerPage}`;
        } else {
          route = `/tickets/${this.projectSelected.id}?page=${page}&per_page=${this.itemsPerPage}&status=${id}`;
        }
        apis.getUrl(route).then((reponse) => {
          this.setStatusProjects(reponse.data.status);
          this.setTickets(reponse.data.tickets.data);
          this.loader = false;
        });
      }
    },
  },
};
</script>

<style>
.btn-d {
  color: white !important;
  margin: 15px;
}

.to {
  margin-right: 5px !important;
  margin-left: 5px !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.proyect {
  text-align: center;
  font-size: 25px;
  padding: 15px;
  margin-bottom: 0px !important;
}

.logs {
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.status-divs {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  flex-flow: wrap;
  width: 100%;
}

.project-upload {
  margin: 15px;
  border: 1px solid;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.form-edit {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 15px;
}

.col-account {
  display: flex;
  align-items: center;
  align-content: center;
  padding: 0px !important;
  padding-bottom: 16px !important;
}

.status {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}

.v-image__image--cover {
  background-size: contain;
}

.p-document {
  margin-bottom: 0 !important;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.note-acount {
  padding: 5px;
  margin: 5px;
  display: flex;
  background: #ffffff;
  color: black;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  border-radius: 10px;
  border: 5px solid #081b38;
  cursor: pointer;
}

.v-chip .v-chip__content {
  width: 100%;
}

.name-note {
  margin-bottom: 0px !important;
}

.direction {
  text-align: center;
  text-decoration: underline;
  color: #455ed6;
  font-size: 20px;
  cursor: pointer;
}

.mdi-brightness-1 {
  color: green !important;
}

.card-des {
  padding: 15px;
}

@media (min-width: 1264px) {
  .container {
    max-width: 96%;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 96%;
  }
}

.files-div {
  width: 100%;
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: solid 1px darkgrey;
}

.pointeTable {
  cursor: pointer;
}

.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 30px;
  min-height: 0;
  margin: 5px;
  min-width: 32px;
  border: azure;
  border-radius: 9px;
}

.v-btn-toggle>.v-btn.v-btn--active {
  color: #003790 !important;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 36px;
  padding: 0px 3px;
}

.tittle-Table {
  background-color: #081b38;
  color: white;
  margin: 0px;
  border-radius: 15px 15px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-style {
  padding: 0px;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  color: #081b38;
  font-size: 15px;
}

.scroll {
  overflow-y: hidden;
  overflow-x: auto;
}

.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
  background: white;
}

.estimate {
  display: flex;
  justify-content: space-between;
  width: 80%;
}
</style>
