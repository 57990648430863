<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="services"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :loading="loader"
      hide-default-footer
      class="elevation-7"
      width="100%"
    >
      <template v-slot:item="{ item }">
        <tr class="pointeTable">
          <td
            @click="openmodal(item.address, item.id, item), (id = item.id)"
            class="hidden-xs-only"
          >
            {{ item.id }}
          </td>
          <td
            @click="openmodal(item.address, item.id, item), (id = item.id)"
            class="hidden-xs-only"
          >
            {{ item.po_purchase }}
          </td>
          <td @click="openmodal(item.address, item.id, item), (id = item.id)">
            {{ item.so }}
          </td>
          <td @click="openmodal(item.address, item.id, item), (id = item.id)">
            {{ item.name }}
          </td>
          <td
            @click="openmodal(item.address, item.id, item), (id = item.id)"
            class="hidden-xs-only"
          >
            {{ getDate(item.start) }}
          </td>
          <td
            @click="openmodal(item.address, item.id, item), (id = item.id)"
            class="hidden-xs-only"
          >
            <p v-for="(tech, i) in item.users" :key="i">
              <small v-if="tech">{{ tech.name }}</small>
            </p>
          </td>
          <td @click="openmodal(item.address, item.id, item), (id = item.id)">
            {{ item.manager.name }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import apis from '@/apis';
import google from '@/apis/google';
import moment from 'moment';
import { FORMAT_DATE } from '@/constants';
import { mapState, mapMutations } from 'vuex';
export default {
  data: () => ({
    loader: false,
    page: 1,
    idelete: null,
    pageCount: 0,
    id: null,
    status: [],
    itemsPerPage: 25,
    message: false,
    address: null,
    loading: false,
    toggle_multiple: [],
    headers: [
      { text: 'Service #', align: 'start', value: 'id' },
      { text: 'PO #', value: 'po' },
      { text: 'SO #', value: 'so' },
      { text: 'Service Name', value: 'name' },
      { text: 'Created on', value: 'start' },
      { text: 'Techs', value: 'tech' },
      { text: 'PM/SC/PE', value: 'manager' },
    ],
  }),
  watch: {
    page() {
      this.getInit();
    },
    id() {
      this.$parent.id = this.id;
    },
    search() {
      if (this.search == null) {
        this.getInit();
      } else {
        this.getSearch();
      }
    },
  },
  computed: {
    ...mapState(['search', 'token', 'services', 'idStatus', 'service']),
  },
  methods: {
    ...mapMutations([
      'setModalProyect',
      'setLocation',
      'setServices',
      'setSearch',
      'setService',
      'setServicePagination',
    ]),
    getDate(date) {
      return moment(date).format(FORMAT_DATE);
    },
    getSearch() {
      apis
        .getUrl(
          `/services/search/${this.search}?page=${this.page}&per_page=${this.itemsPerPage}&status=${this.idStatus}`,
        )
        .then((reponse) => {
          this.setServices(reponse.data.data);
          this.$parent.pagination = reponse.data.last_page;
          this.loader = false;
        });
    },
    async openmodal(address, id, item) {
      this.setService(item);
      await google.geocode(address).then((reponse) => {
        this.setLocation({
          lat: reponse.data.results[0].geometry.location.lat,
          lng: reponse.data.results[0].geometry.location.lng,
        });
      });

      this.$router.push(`/services/${id}`);
    },
    getInit(id, page) {
      this.loader = true;
      var route;
      if (this.search) {
        this.getSearch();
      } else {
        if (!id) {
          route = `${process.env.VUE_APP_BASE_URL_API}/api/v1/services?page=${page}&per_page=${this.itemsPerPage}`;
        } else {
          route = `${process.env.VUE_APP_BASE_URL_API}/api/v1/services?page=${page}&per_page=${this.itemsPerPage}&status=${id}`;
        }
        apis.getUrl(route).then((reponse) => {
          this.setServices(reponse.data.data);
          this.loader = false;
          this.setServicePagination(reponse.data.last_page);
          this.$parent.pagination = reponse.data.last_page;
        });
      }
    },
  },
};
</script>
