<template>
  <v-container class="services" justify="center">
    <v-expand-transition>
      <v-alert type="success" v-if="message">
        Record created successfully.
      </v-alert>
    </v-expand-transition>
    <v-row>
      <v-col xs="10" md="8" align-self="center">
        <v-btn-toggle v-model="toggle_multiple" class="scroll">
          <v-btn
            depressed
            v-for="(statu, i) in status_list"
            :key="i"
            @click="(page = 1), (id_status = statu.id), getInits(statu.id)"
          >
            {{ statu.name }} ({{ statu.count }})
          </v-btn>
          <v-btn depressed @click="(page = 1), (id_status = 0), getInits(0)">
            all ({{ totalService }})
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col dense xs="2" md="auto" align-self="center">
        <v-btn
          @click="
            (toggle_multiple = 1), getInits(3), (page = 1), (id_status = 3)
          "
        >
          <v-icon color="#081b38">mdi-refresh</v-icon>
        </v-btn>
      </v-col>
      <v-col dense xs="6" md="auto" align-self="center">
        <div class="text-center">
          <v-skeleton-loader
            v-if="paginate"
            type="card-avatar, article, actions"
            height="30px"
            width="100%"
          ></v-skeleton-loader>
          <v-pagination
            v-else
            v-model="page"
            :length="servicePagination"
            color="#081b38"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="tittle-Table">
        <h2>Current Services</h2>
      </v-col>
      <v-col class="table-style">
        <Services ref="services" />
      </v-col>
    </v-row>
    <v-row class="pagination">
      <v-col dense xs="6" md="auto" align-self="center">
        <div class="text-center">
          <v-skeleton-loader
            v-if="paginate"
            type="card-avatar, article, actions"
            height="30px"
            width="100%"
          ></v-skeleton-loader>
          <v-pagination
            v-else
            v-model="page"
            :length="servicePagination"
            color="#081b38"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <NewProject />
  </v-container>
</template>

<script>
import Services from '@/components/ServicesTable.vue';
import NewProject from '@/components/NewProject.vue';
import { mapState, mapMutations } from 'vuex';
import apis from '@/apis';
import google from '@/apis/google';
export default {
  data: () => ({
    page: 1,
    id: null,
    editService: false,
    id_status: null,
    paginate: true,
    filter_id: null,
    status_list: [],
    pagination: 1,
    itemsPerPage: 0,
    message: false,
    toggle_multiple: 1,
    address: null,
    totalService: 0,
  }),
  computed: {
    ...mapState([
      'servicePagination',
      'user',
      'status',
      'modalProyect',
      'token',
      'lat',
      'lng',
      'services',
      'idStatus',
      'serviceActives',
    ]),
  },
  components: {
    NewProject,
    Services,
  },
  mounted() {
    this.getInits(3);
    this.id_status = 3;
    this.status_list = this.status;
    this.status_list.forEach((element) => {
      this.totalService = parseInt(element.count) + this.totalService;
    });
  },
  watch: {
    message() {
      setTimeout(() => (this.message = false), 7000);
    },
    page() {
      this.changePage();
    },
    status() {
      this.totalService = 0;
      this.status_list = this.status;
      this.status_list.forEach((element) => {
        this.totalService = parseInt(element.count) + this.totalService;
      });
    },
    id_status() {
      this.setIdStatus(this.id_status);
    },
  },
  methods: {
    ...mapMutations([
      'setModalProyect',
      'setLocation',
      'setIdStatus',
      'setFilterService',
    ]),
    setLocation(id) {
      apis.getUrl(`/service/${id}`).then((reponse) => {
        google
          .geocode(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${reponse.data.data.address}&key=${process.env.VUE_APP_API_KEY_MAPS}`,
          )
          .then((res) => {
            this.setLocation(
              res.data.results[0].geometry.location.lat,
              res.data.results[0].geometry.location.lng,
            );
            setTimeout(() => {
              this.setModalProyect(true);
              this.id = this.$route.query.s;
            }, 1000);
          });
      });
    },
    closeModal() {
      this.setModalProyect(false);
    },
    edit() {
      this.$refs.insideServices.edit = !this.$refs.insideServices.edit;
    },
    validate() {
      this.$refs.edit.validate();
    },
    changePage() {
      this.$refs.services.getInit(this.id_status, this.page);
    },
    reload() {
      this.$refs.services.getInit();
    },
    getInits(id) {
      this.setFilterService(id);
      this.setIdStatus(id);
      this.paginate = true;
      this.$refs.services.getInit(id, this.page);
      setTimeout(() => (this.paginate = false), 2100);
    },
  },
};
</script>

<style>
@media (min-width: 1264px) {
  .container {
    max-width: 96%;
  }
}
@media (min-width: 960px) {
  .container {
    max-width: 96%;
  }
}
.pointeTable {
  cursor: pointer;
}
.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 30px;
  min-height: 0;
  margin: 5px;
  min-width: 32px;
  border: azure;
  border-radius: 9px;
}
.v-btn-toggle > .v-btn.v-btn--active {
  color: #003790 !important;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 36px;
  padding: 0px 3px;
}
.tittle-Table {
  background-color: #081b38;
  color: white;
  margin: 0px;
  border-radius: 15px 15px 0px 0px;
}
.table-style {
  padding: 0px;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  color: #081b38;
  font-size: 15px;
}
.scroll {
  overflow-y: hidden;
  overflow-x: auto;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
  background: white;
}
</style>
