<template>
  <v-container fluid>
      
    <v-data-iterator
      :items='items'
      :items-per-page.sync='itemsPerPage'
      :page.sync='page'
      :search='search'
      :sort-by='sortBy.toLowerCase()'
      :sort-desc='sortDesc'
      hide-default-footer
    >
      <template v-slot:header>
        <v-toolbar
          dark
          color='#081b38'
          class='mb-1'
        >
          <v-text-field
            v-model='search'
            clearable
            flat
            solo-inverted
            hide-details
            prepend-inner-icon='mdi-magnify'
            label='Search'
          ></v-text-field>
          <template v-if='$vuetify.breakpoint.mdAndUp'>
            <v-spacer></v-spacer>
            <v-select
              v-model='sortBy'
              flat
              solo-inverted
              hide-details
              :items='keys'
              prepend-inner-icon='mdi-magnify'
              label='Sort by'
            ></v-select>
            <v-spacer></v-spacer>
            <v-btn-toggle
              v-model='sortDesc'
              mandatory
            >
              <v-btn
                large
                depressed
                color='#304058'
                :value='false'
              >
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn
                large
                depressed
                color='#304058'
                :value='true'
              >
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-toolbar>
      </template>

      <template v-slot:default='props'>
        <v-row>
          <v-col
            v-for='(item, x) in props.items'
            :key='item.name+x'
            cols='12'
            sm='6'
            md='4'
            lg='3'
          >
            <v-card>
              <v-card-title class='subheading font-weight-bold'>
               <v-avatar>
                    <img
                        :src='item.avatar'
                        :alt='item.name'
                        size='30'
                    >
                </v-avatar> 
                {{ item.name }}
              </v-card-title>

              <v-divider></v-divider>

              <v-list dense>
                <v-list-item
                  v-for='(key, index) in filteredKeys'
                  :key='index'
                >
                  <v-list-item-content :class="{ 'blue--text': sortBy === key }">
                    {{ key }}:
                  </v-list-item-content>
                  <v-list-item-content
                    v-if="key === 'Locations'"
                    class='align-end'
                    :class="{ 'blue--text': sortBy === key }"
                  >
                    <a @click='goMap(item.location_in.lat, item.location_in.lon)' target='_blank' rel='noopener noreferrer'>Location in  go</a>  <br>
                    <a v-if='item.location_on' @click='goMap(item.location_on.lat, item.location_on.lon)' target='_blank' rel='noopener noreferrer'>Location out go</a>  
                  </v-list-item-content>
                  <v-list-item-content
                    v-else
                    class='align-end'
                    :class="{ 'blue--text': sortBy === key }"
                  >
                    {{ item[key.toLowerCase()] }} 
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row
          class='mt-2'
          align='center'
          justify='center'
        >
          <span class='grey--text'>Items per page</span>
          <v-menu offset-y>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                dark
                text
                color='#081b38'
                class='ml-2'
                v-bind='attrs'
                v-on='on'
              >
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for='(number, index) in itemsPerPageArray'
                :key='index'
                @click='updateItemsPerPage(number)'
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <span
            class='mr-4
            grey--text'
          >
            Page {{ page }} of {{ last_page }}
          </span>
          <v-btn
            fab
            dark
            color='#081b38'
            class='mr-1'
            @click='formerPage'
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            fab
            dark
            color='#081b38'
            class='ml-1'
            @click='nextPage'
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import { FORMAT_DATE } from '@/constants';
import moment from 'moment';
  export default {
    data () {
      return {
        itemsPerPageArray: [24, 72, 360],
        search: '',
        filter: {},
        sortDesc: true,
        page: 1,
        last_page: 1,
        itemsPerPage: 24,
        sortBy: 'date',
        keys: [
          'Avatar',
          'Name',
          'Type',
          'Date',
          'In',
          'Out',
          'Time',
          'Locations',
        ],
        items: [],
        location:[],
        gettingLocation: false,
        errorStr:null,
      }
    },
    computed: {
        ...mapState(['token','clock','time_cards']),
      numberOfPages () {
        return Math.ceil(this.last_page / this.itemsPerPage)
      },
      filteredKeys () {
        return this.keys.filter(key => key !== 'Avatar'  && key !== 'Name')
      },
    },
    watch: {
        clock: {
            handler: function() {
                this.get()
            },
            deep: true
        },
        time_cards(){
          this.get()
        },
        page(){
          this.$store.dispatch('cheks', {
            page: this.page,
            itemsPerPage: this.itemsPerPage
          })
        },
        itemsPerPage(){
          this.page = 1
          this.$store.dispatch('cheks', {
            page: this.page,
            itemsPerPage: this.itemsPerPage
          })

        }
    },
    mounted() {
        if (this.time_cards.data) {
          this.get()
        } else {
          this.$store.dispatch('cheks', {
            page: this.page,
            itemsPerPage: this.itemsPerPage
          })
        }
    },
    methods: {
        
    goMap(lat, lon) {
      window.open(
        `https://www.google.com/maps/place/${lat}, ${lon}`,
        '_blank'
      );
    },
      nextPage () {
        if (this.page + 1 <= this.last_page) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      },
      get(){
            this.items=[]
            this.last_page = this.time_cards.last_page
        for (let time_card of this.time_cards.data){

            var location_in= JSON.parse(time_card.location_in)
            var location_on=JSON.parse(time_card.location_on)
            var out;
            if (time_card.check_on) {
                out= moment(time_card.check_on).format('hh:mm:ss a')
            }else{
                out= '-'
            }
            var item = {  
            avatar: time_card.by.avatar,
            name: time_card.by.name,
            type: time_card.by.type,
            date: moment(time_card.check_in).format(FORMAT_DATE),
            in:  moment(time_card.check_in).format('hh:mm:ss a'),
            out: out,
            time: time_card.duration,
            location_in: location_in,
            location_on: location_on
            }
            this.items.push(item)
        }
      }
    },
  }
</script>