<template>
  <div class='about'>
    <h1>Reditrect to https://drive.google.com .......</h1>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    mounted() {
        setTimeout(() => (this.go()), 1000);
    },
    methods: {
        go(){
        window.open(
            `https://drive.google.com/drive/u/0/my-drive`,
            '_blank'
        );
        this.$router.push('/');
        }
    },
    computed: {
      ...mapState(['token','user']),
    },
}
</script>