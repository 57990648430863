<template>
  <div class='about'>
    <h1>Reditrect to https://front-inventory.gjsigns.com .......</h1>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    mounted() {
        setTimeout(() => (this.go()), 1000);
    },
    methods: {
        go(){
        window.open(
            `https://inventory.gjsigns.com?token=${this.token}&userid=${this.user.id}&username=${this.user.name}&userpicture=${this.user.avatar}&useremail=${this.user.email}&usertype=${this.user.type}`,
            '_blank'
        );
        this.$router.push('/');
        }
    },
    computed: {
      ...mapState(['token','user']),
    },
}
</script>