<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="1200">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="#081b38"
          dark
          v-bind="attrs"
          v-on="on"
          class="btn-estimate"
          @click="newEstimate()"
        >
          <v-icon color="white" class="icon-bt"
            >mdi-clipboard-text-multiple</v-icon
          >New Proposal
        </v-btn>
      </template>
      <v-card>
        <v-row class="head" no-gutters>
          <v-col md="4" lg="4" cols="12" sm="12">
            <v-img
              class="img"
              max-width="250"
              src="../assets/img/logo-gjsigns.png"
            ></v-img>
          </v-col>
          <v-col md="3" lg="3" cols="12" sm="12" class="text-head">
            <p>
              1405 W. Olympic Blvd. <br />
              Montebello, CA 90640
            </p>
          </v-col>
          <v-col md="3" lg="3" cols="12" sm="12" class="text-head">
            <p>
              (323) 284-4000 <br />
              <a href="">www.gjsigns.com</a>
            </p>
          </v-col>
          <v-col md="2" lg="2" cols="12" sm="12" class="text-head">
            <v-card>
              <v-toolbar
                color="#001436"
                dark
                dense
                flat
                class="tool-title-text"
              >
                <v-toolbar-title class="body-2">
                  <p class="text-tool">Estimate #</p>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text class="background">
                {{ id }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="head" no-gutters>
          <v-col md="3" lg="3" cols="12" sm="12" class="col-center">
            <v-card class="large">
              <v-toolbar
                color="#001436"
                dark
                dense
                flat
                class="tool-title-text"
              >
                <v-toolbar-title class="body-2">
                  <p class="text-tool">Project Address</p>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text class="background">
                {{ projectSelected.address }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="4" lg="4" cols="12" sm="12" class="col-center">
            <v-card class="large">
              <v-toolbar
                color="#001436"
                dark
                dense
                flat
                class="tool-title-text"
              >
                <v-toolbar-title class="body-2">
                  <p class="text-tool">Client</p>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text class="background">
                {{ projectSelected.customer.name }}
                {{ projectSelected.customer.address }}
                {{ projectSelected.customer.company }}
                {{ projectSelected.customer.customer }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="3" lg="3" cols="12" sm="12" class="col-center">
            <v-card class="large">
              <v-toolbar
                color="#001436"
                dark
                dense
                flat
                class="tool-title-text"
              >
                <v-toolbar-title class="body-2">
                  <p class="text-tool">Contact</p>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text class="background">
                {{ projectSelected.customer.name }}
                {{ projectSelected.customer.email }}
                {{ projectSelected.customer.phone }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="2" lg="2" cols="12" sm="12" class="col-center">
            <v-card class="large-min">
              <v-toolbar
                color="#001436"
                dark
                dense
                flat
                class="tool-title-text"
              >
                <v-toolbar-title class="body-2">
                  <p class="text-tool">Date</p>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text class="background">
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormatted"
                      readonly
                      hint="MM/DD/YYYY format"
                      v-bind="attrs"
                      v-on="on"
                      style="width: 75%;"
                      @blur="date = parseDate(dateFormatted)"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-card-text>
            </v-card>
            <v-card class="large-min" style="margin-top: 10px;">
              <v-toolbar
                color="#001436"
                dark
                dense
                flat
                class="tool-title-text"
              >
                <v-toolbar-title class="body-2">
                  <p class="text-tool">Expiration Date</p>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text class="background">
                {{ expirationDate }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col md="10" lg="10" cols="12" sm="12" class="col-center">
            <v-card style="margin-top: 10px; width: 40%; margin-bottom: 15px;">
              <v-toolbar
                color="#001436"
                dark
                dense
                flat
                class="tool-title-text"
              >
                <v-toolbar-title class="body-2">
                  <p class="text-tool">Project Description</p>
                </v-toolbar-title>
              </v-toolbar>
            </v-card>
          </v-col>
          <v-col md="2" lg="2" cols="12" sm="12" class="col-center">
            <v-card style="margin-top: 10px; width: 95%; margin-bottom: 15px;">
              <v-toolbar
                color="#001436"
                dark
                dense
                flat
                class="tool-title-text"
              >
                <v-toolbar-title class="body-2">
                  <p class="text-tool">Amount</p>
                </v-toolbar-title>
              </v-toolbar>
            </v-card>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          v-for="(estimate, x) in estimates"
          :key="x"
          style="margin-top: 15px;"
        >
          <v-col md="10" lg="10" cols="12" sm="12" class="col-center">
            <div style="width: 90%;">
              <vue-editor
                v-model="estimate.description"
                id="editor2"
                name="description"
              ></vue-editor>
            </div>
          </v-col>
          <v-col md="2" lg="2" cols="12" sm="12" class="col-center">
            <v-btn
              block
              color="blue"
              class="btn-icon"
              @click="copyEstimate(estimate, x)"
              >Paste Scope
              <v-icon dark right>
                mdi-plus-box-multiple
              </v-icon></v-btn
            >
            <v-card
              class="large-min"
              style="margin-top: 10px; align-content: flex-end; align-items: flex-end; display: flex; justify-content: center;"
            >
              <div>
                <v-card-text class="background">
                  <v-text-field
                    v-model="estimate.amount"
                    label="Amount"
                    type="number"
                    prefix="$"
                  >
                  </v-text-field>
                  <v-radio-group v-model="estimate.taxes">
                    <v-radio :value="1" label="Taxable" class="radio"></v-radio>
                    <v-radio
                      :value="0"
                      label="Non Taxable"
                      class="radio"
                    ></v-radio>
                  </v-radio-group>
                </v-card-text>
                <v-btn
                  block
                  color="green"
                  class="btn-icon"
                  @click="addEstimate()"
                  >Add line
                  <v-icon dark right>
                    mdi-plus-circle
                  </v-icon></v-btn
                >
                <v-btn
                  block
                  color="red"
                  class="btn-icon"
                  @click="deleteEstimate(x)"
                  :disabled="x === 0"
                  >Delete
                  <v-icon dark right>
                    mdi-minus-circle
                  </v-icon></v-btn
                >
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters style="margin-top: 25px;">
          <v-col md="10" lg="10" cols="12" sm="12" class="col-center">
            <div class="div-fotter">
              <div class="div-fotter-form">
                <p class="text-tool">Deposit %</p>
                <input
                  v-model="deposit"
                  type="text"
                  class="div-fotter-input"
                  placeholder="%"
                />
              </div>
              <div class="div-fotter-form">
                <p class="text-tool">Deposit Amount $</p>
                <input
                  v-model="depositAmount"
                  type="text"
                  class="div-fotter-input"
                  placeholder="$"
                />
              </div>
              <div class="div-fotter-form">
                <p class="text-tool">Terms</p>
                <v-combobox
                  v-model="select"
                  :items="items"
                  outlined
                  color="white"
                  item-text="days"
                  dense
                  class="combo-es"
                ></v-combobox>
              </div>
            </div>
          </v-col>
          <v-col md="2" lg="2" cols="12" sm="12" class="col-center">
            <div class="div-fotter-left">
              <div class="div-column">
                <p class="p-margin">Sub-Total:</p>
                <p class="p-margin">Tax:</p>
                <p class="p-margin">Total:</p>
              </div>
              <div class="div-column">
                <p class="p-margin">{{ computedMoneyFormatted.priceCurrency }}</p>
                <p class="p-margin">{{ computedMoneyFormatted.tax }}</p>
                <p class="p-margin">{{ computedMoneyFormatted.total }}</p>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col md="10" lg="10" cols="12" sm="12" class="col-center">
            <div style="width: 95%;">
              <vue-editor
                v-model="select.terms"
                id="editorTerm"
                name="description"
              ></vue-editor>
            </div>
          </v-col>
          <v-col md="2" lg="2" cols="12" sm="12" class="col-center">
            <div class="div-save">
              <v-btn
                block
                color="green"
                elevation="2"
                class="btn-icon"
                @click="save()"
                :loading="btnloader"
              >
                Save</v-btn
              >
            </div>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <v-row no-gutters style="height: 60px;"></v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { VueEditor } from 'vue2-editor';
import { mapState } from 'vuex';
import apis from '@/apis';
export default {
  data: (vm) => ({
    id: 'Save to get id',
    dialog: false,
    amount: 0.0,
    modal: false,
    btnloader: false,
    deposit: 0,
    depositAmount: 0,
    radioGroup: 1,
    content: '',
    expirationDate: '30 days auto',
    estimateDefault: {
      description: '',
      amount: 0,
      taxes: 0,
    },
    estimates: [
      {
        description: '',
        amount: 0,
        taxes: 0,
      },
    ],
    select: { days: '30 Days', terms: '30' },
    items: [],
    dateFormatted: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    ),
    date: new Date().toISOString().substr(0, 10),
  }),
  components: {
    VueEditor,
  },
  mounted() {
    this.terms.map((term) => {
      let item = {
        days: term.name,
        terms: term.text,
      };
      this.items.push(item);
    });
    this.select = this.items[0];
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    addEstimate() {
      this.estimates.push({
        description: '',
        amount: 0,
        amountFormat: '',
        taxes: 0,
      });
    },
    newEstimate() {
      this.$emit('newEstimate', null);
    },
    deleteEstimate(index) {
      this.estimates.splice(index, 1);
    },
    copyEstimate(estimate, index) {
      const current = { ...estimate };
      current.description = this.projectSelected.description;
      this.estimates.splice(index, 1, current);
    },
    save() {
      this.btnloader = true;
      let post;
      this.estimates.forEach((estimate) => {
        var form = 0;
        form = estimate.amount;
        estimate.amountFormat = parseFloat(form).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        });
      });
      if (this.estimate.id) {
        post = {
          id: this.estimate.id,
          projectId: this.projectSelected.id,
          date: this.date,
          estimates: this.estimates,
          term: this.select,
          depositAmount: this.depositAmount,
          deposit: this.deposit,
          priceSub: this.computedMoneyFormatted.priceCurrency,
          tax: this.computedMoneyFormatted.tax,
          total: this.computedMoneyFormatted.total,
        };
      } else {
        post = {
          id: null,
          projectId: this.projectSelected.id,
          date: this.date,
          estimates: this.estimates,
          term: this.select,
          depositAmount: this.depositAmount,
          deposit: this.deposit,
          priceSub: this.computedMoneyFormatted.priceCurrency,
          tax: this.computedMoneyFormatted.tax,
          total: this.computedMoneyFormatted.total,
        };
      }
      apis
        .addEstimate(post)
        .then((data) => {
          this.$emit('updateEstimates', data.data);
          this.$swal.fire('Estimate added!', '', 'success');
          this.dialog = false;
          this.btnloader = false;
        })
        .catch((error) => {
          this.btnloader = false;
          this.$swal.fire('server error refreshes the page!', '', error);
        });
    },
  },
  props: {
    estimate: {},
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    deposit() {
      this.depositAmount = (
        (this.computedMoneyFormatted.price + this.computedMoneyFormatted.tax) *
        (this.deposit / 100)
      ).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      });
    },
    estimate() {
      if (this.estimate.data) {
        this.expirationDate = JSON.parse(this.estimate.data).expirationDate;
        this.estimates = [];
        JSON.parse(this.estimate.data).project.map((est) => {
          let e = {
            description: est.description,
            amount: est.amount,
            taxes: est.taxes,
          };
          this.estimates.push(e);
        });
        this.deposit = JSON.parse(this.estimate.data).deposit;
        this.depositAmount = JSON.parse(this.estimate.data).depositAmount;
        this.select = JSON.parse(this.estimate.data).term;
      } else {
        this.expirationDate = '30 days auto';
        this.estimates = [
          {
            description: '',
            amount: 0,
            taxes: 0,
          },
        ];
      }
      if (this.estimate.id) {
        this.id = '2000-' + this.estimate.id;
        this.date = JSON.parse(this.estimate.data).date;
      } else {
        this.id = 'Save to get id';
        this.date = new Date().toISOString().substr(0, 10);
      }
    },
  },
  computed: {
    ...mapState(['token', 'taxes', 'terms', 'projectSelected', 'ticket']),

    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    computedMoneyFormatted() {
      var price = 0;
      var tax = 0;
      this.estimates.map((estimate) => {
        price = parseFloat(price) + parseFloat(estimate.amount);
        if (estimate.taxes) {
          tax =
            parseFloat(tax) +
            parseFloat(estimate.amount) * (parseFloat(this.taxes.value) / 100);
        }
      });
      var tot = price + tax;
      return {
        price: price,
        tax: tax,
        priceCurrency: price.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        }),
        taxCurrency: tax.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        }),
        total: tot.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        }),
      };
    },
    
  },
};
</script>
<style>
.btn-estimate {
  margin-top: 15px;
  text-align: center;
  color: white !important;
  padding: 10px !important;
  border-radius: 20px;
}
.icon-bt {
  padding: 10px;
}
.combo-es {
  width: 60% !important;
  margin-left: 5px !important;
  top: 13px;
}
.combo-es .v-input__slot {
  width: 60% !important;
  background: aliceblue !important;
}
.p-margin {
  margin-bottom: 0px !important;
}
.div-column {
  text-align: end;
  width: 40%;
}
.div-fotter-left {
  background: #001436;
  border-radius: 10px;
  color: white;
  display: flex;
  justify-content: center;
}
.div-fotter-input {
  background: white;
  width: 40%;
  border-radius: 9px;
  height: 55%;
  margin-left: 5px;
  padding: 5px;
}
.div-fotter-form {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.div-fotter {
  background: #001436;
  border-radius: 9px;
  width: 95%;
  display: flex;
  height: 75px;
  justify-content: space-around;
  color: white;
  align-items: center;
}
.btn-icon {
  color: white !important;
  font-size: small !important;
}
.radio {
  background-color: unset !important;
}
.large-min {
  width: 80%;
}
.large {
  width: 90%;
}
.col-center {
  text-align: -webkit-center;
}
.head {
  width: 100%;
}
.img {
  margin: 25px;
}
.text-head {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.tool-title-text {
  display: flex;
  justify-content: center;
  align-content: center;
}
.text-tool {
  margin: 0 !important;
  font-size: large;
  font-weight: bolder;
}
.background {
  background: #f6f6f6;
  font-size: large;
}
.div-save {
  display: flex;
  align-content: flex-end;
  flex-direction: column-reverse;
}
</style>
